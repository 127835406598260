import {
    ACTION_FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS,
    ACTION_FETCHING_MAIN_BLOG_POSTS_COMPLETED,
    ACTION_STORE_MAIN_BLOG_POSTS,
    ACTION_STORE_BLOG_CATEGORIES,
    ACTION_SET_ACTIVE_CATEGORY,
    ACTION_FETCHING_CATEGORY_POSTS_IN_PROGRESS,
    ACTION_FETCHING_CATEGORY_POSTS_COMPLETED,
    ACTION_STORE_FETCHED_POSTS,
    ACTION_SET_BLOG_PAGE,
    ACTION_STORE_ADDITIONAL_POSTS,
    ACTION_CLEAR_BLOG_STATE,
    ACTION_FETCHING_ALL_POSTS_IN_PROGRESS,
    ACTION_STORE_FETCHED_ALL_POSTS,
    ACTION_FETCHING_ALL_POSTS_COMPLETED,
    ACTION_FETCHING_ALL_CATEGORIES_IN_PROGRESS,
    ACTION_STORE_FETCHED_ALL_CATEGORIES,
    ACTION_FETCHING_ALL_CATEGORIES_COMPLETED,
    ACTION_CREATING_POST_IN_PROGRESS,
    ACTION_STORE_CREATED_POST,
    ACTION_CREATING_POST_COMPLETED,
    ACTION_FETCHING_POST_OPTIONS_IN_PROGRESS,
    ACTION_FETCHING_POST_OPTIONS_COMPLETED,
    ACTION_STORE_POST_OPTIONS,
    ACTION_EDITING_POST_IN_PROGRESS,
    ACTION_EDITING_POST_COMPLETED,
    ACTION_DELETING_POST_IN_PROGRESS,
    ACTION_DELETING_POST_COMPLETED,
    ACTION_DELETING_CATEGORY_IN_PROGRESS,
    ACTION_DELETING_CATEGORY_COMPLETED,
    ACTION_CREATING_CATEGORY_IN_PROGRESS,
    ACTION_CREATING_CATEGORY_COMPLETED,
    ACTION_EDITING_CATEGORY_IN_PROGRESS,
    ACTION_EDITING_CATEGORY_COMPLETED,
    ACTION_FETCHING_SINGLE_POST_IN_PROGRESS,
    ACTION_FETCHING_SINGLE_POST_COMPLETED,
    ACTION_STORE_FETCHED_POST,
    ACTION_FETCHING_SEARCH_POSTS_IN_PROGRESS,
    ACTION_STORE_SEARCH_POSTS,
    ACTION_FETCHING_SEARCH_POSTS_COMPLETED
} from './blogActionsList';

import api from '../../api';
import history from '../router/history';

export const fetchMainBlogPosts = () => {
    return async (dispatch, getState) => {
        try {
           dispatch(ACTION_FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS());
            const response = await api.mainBlogPosts();
            if(response?.data) {
                dispatch(ACTION_FETCHING_MAIN_BLOG_POSTS_COMPLETED());
                dispatch(ACTION_STORE_MAIN_BLOG_POSTS(response.data.main, response.data.posts));
                dispatch(ACTION_STORE_BLOG_CATEGORIES(response.data.categories))
            }
        } catch (error) {
            dispatch(ACTION_FETCHING_MAIN_BLOG_POSTS_COMPLETED());
        }
    }
}

export const setActiveCategory = (category) => {
    return async (dispatch, getState) => {
        const activeCategory = getState().blog.activeCategory;
        if(activeCategory === category) category = null;
        try {
            dispatch(ACTION_FETCHING_CATEGORY_POSTS_IN_PROGRESS());
            dispatch(ACTION_SET_ACTIVE_CATEGORY(category));
            dispatch(ACTION_SET_BLOG_PAGE(1));

            if(category){
                const response = await api.getPosts(category, 1);
                if (response?.data) {
                    dispatch(ACTION_STORE_FETCHED_POSTS(response.data.posts, response.data.totalItems));
                }
            }
            dispatch(ACTION_FETCHING_CATEGORY_POSTS_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_CATEGORY_POSTS_COMPLETED());
        }
    }
}

export const getMorePosts = () => {
    return async (dispatch, getState) => {
        try {
            
            const page = getState().blog.categoryPage;
            const category = getState().blog.activeCategory;

            dispatch(ACTION_FETCHING_CATEGORY_POSTS_IN_PROGRESS());
            const response = await api.getPosts(category, page);
            if (response && response.data) {
                dispatch(ACTION_STORE_ADDITIONAL_POSTS(response.data.posts));
            }
            dispatch(ACTION_FETCHING_CATEGORY_POSTS_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_CATEGORY_POSTS_COMPLETED());
        }
    }
}

export const setBlogPage = (page) => {
    return (dispatch) => {
        dispatch(ACTION_SET_BLOG_PAGE(page));
    }
}

export const clearBlogState = () => {
    return (dispatch) => {
        dispatch(ACTION_CLEAR_BLOG_STATE());
    }
}

export const getAllPosts = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_FETCHING_ALL_POSTS_IN_PROGRESS());
            const response = await api.getAllPosts();
            if (response?.data?.posts) {
                dispatch(ACTION_STORE_FETCHED_ALL_POSTS(response.data.posts))
            }
            dispatch(ACTION_FETCHING_ALL_POSTS_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_ALL_POSTS_COMPLETED());
        }
    }
}

export const getAllCategories = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_FETCHING_ALL_CATEGORIES_IN_PROGRESS());
            const response = await api.getAllCategories();
            if (response?.data?.categories) {
                dispatch(ACTION_STORE_FETCHED_ALL_CATEGORIES(response.data.categories))
            }
            dispatch(ACTION_FETCHING_ALL_CATEGORIES_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_ALL_CATEGORIES_COMPLETED());
        }
    }
}

export const createPost = (post) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_CREATING_POST_IN_PROGRESS());
            const response = await api.createPost(post);
            if (response?.data?.post) {
                dispatch(ACTION_STORE_CREATED_POST(response.data.post));
                history.replace('/dashboard/posts');
            }
            dispatch(ACTION_CREATING_POST_COMPLETED());
        } catch (error) {
            dispatch(ACTION_CREATING_POST_COMPLETED());
        }
    }
}
export const editPost = (post, id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_EDITING_POST_IN_PROGRESS());
            const response = await api.editPost(post, id);
            if (response?.data?.post) {
                history.replace('/dashboard/posts');
            }
            dispatch(ACTION_EDITING_POST_COMPLETED());
        } catch (error) {
            dispatch(ACTION_EDITING_POST_COMPLETED());
        }
    }
}

export const deletePost = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_DELETING_POST_IN_PROGRESS());
            const response = await api.deletePost(id);
            if (response?.data) {
                history.replace('/dashboard/posts');
            }
            dispatch(ACTION_DELETING_POST_COMPLETED());
        } catch (error) {
            dispatch(ACTION_DELETING_POST_COMPLETED());
        }
    }
}

export const getPostOptions = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_FETCHING_POST_OPTIONS_IN_PROGRESS());
            const response = await api.getPostOptions();
            if (response?.data?.options) {
                dispatch(ACTION_STORE_POST_OPTIONS(response.data.options))
            }
            dispatch(ACTION_FETCHING_POST_OPTIONS_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_POST_OPTIONS_COMPLETED());
        }
    }
}

export const deleteCategory = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_DELETING_CATEGORY_IN_PROGRESS());
            await api.deleteCategory(id);

            dispatch(ACTION_DELETING_CATEGORY_COMPLETED());
        } catch (error) {
            dispatch(ACTION_DELETING_CATEGORY_COMPLETED());
        }
    }
}

export const createCategory = (category) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_CREATING_CATEGORY_IN_PROGRESS());
            const response = await api.createCategory(category);
            if (response?.data?.category) {
                history.replace('/dashboard/categories');
            }
            dispatch(ACTION_CREATING_CATEGORY_COMPLETED());
        } catch (error) {
            dispatch(ACTION_CREATING_CATEGORY_COMPLETED());
        }
    }
}

export const editCategory = (category, id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_EDITING_CATEGORY_IN_PROGRESS());
            const response = await api.editCategory(category, id);
            if (response?.data?.category) {
                history.replace('/dashboard/categories');
            }
            dispatch(ACTION_EDITING_CATEGORY_COMPLETED());
        } catch (error) {
            dispatch(ACTION_EDITING_CATEGORY_COMPLETED());
        }
    }
}

export const fetchPost = (id) => {
    return async(dispatch, getState) => {
        try {
            dispatch(ACTION_FETCHING_SINGLE_POST_IN_PROGRESS());
            const response = await api.fetchPost(id);
            if (response?.data?.post) {
                dispatch(ACTION_STORE_FETCHED_POST(response.data.post, response.data.relatedPosts, response.data.newestPosts, response.data.popularPosts));
            }
            
            dispatch(ACTION_FETCHING_SINGLE_POST_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_SINGLE_POST_COMPLETED());
        }
    }
}

export const getSearchPosts = (search) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_FETCHING_SEARCH_POSTS_IN_PROGRESS());
            const response = await api.getSearchPosts(search);
            if (response?.data?.posts) {
                dispatch(ACTION_STORE_SEARCH_POSTS(response?.data?.posts));
            }

            dispatch(ACTION_FETCHING_SEARCH_POSTS_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_SEARCH_POSTS_COMPLETED());
        }
    }
}