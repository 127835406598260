import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../store/actions/authActions';
import { setActiveCategory } from '../../store/actions/blogActions';

import history from '../../store/router/history';

import logo from '../../assets/images/opcinaLogo.svg';
import opstinaLogo from '../../assets/images/opstinaLogo.svg';
import hamburger from '../../assets/icons/hamburger.svg';
import x from '../../assets/icons/x.svg';

class MobileHeader extends Component {
    state = {
        open: false
    }
    render() {
        const activePath = this.props.location.pathname;
        const { region, categories, activeCategory } = this.props;
        return (
            <div id="mobile-header" className={`mobile-header ${history.location.pathname === '' ? 'mobile-header--mobile-blog' : ''}`}>
                
                <Link to="/">
                    {region ? <img src={region === 'FB' ? logo : opstinaLogo} alt="logo" className="mobile-header__logo" /> : null}
                </Link>
           
                <img onClick={() => { this.setHamburgerOpen(true)}} src={hamburger} alt="hamburger" className="" />

                <div className={`hamburger ${this.state.open ? 'hamburger--open' : ''}`}>
                    <div onClick={() => { this.setHamburgerOpen(false) }} className={`hamburger__backdrop ${this.state.open ? 'hamburger__backdrop--open' : ''}`} />
                    <div className={`hamburger__main ${this.state.open ? 'hamburger__main--open' : ''}`}>
                        {region ? <img src={region === 'FB' ? logo : opstinaLogo} alt="logo" className="hamburger__logo" /> : null}
                        
                        <img onClick={() => { this.setHamburgerOpen(false) }} src={x} alt="close" className="hamburger__close" />
                        <h1 className="hamburger__titles">
                            Navigacija
                        </h1>
                        <div className="hamburger__links">
                            <Link to="/">
                                <div className={`hamburger__link ${activePath === '/' ? 'hamburger__link--active' : ''}`}>
                                    Početna
                                </div>
                            </Link>
                            {region ?
                                <Link to="/municipalities">
                                    <div className={`hamburger__link ${activePath.startsWith('/municipalities') ? 'hamburger__link--active' : ''}`}>
                                        {region === 'FB' ? 'Općine' : 'Opštine'}
                                    </div>
                                </Link>
                            : null}
                            <Link to="/mayors">
                                <div className={`hamburger__link ${activePath.startsWith('/mayors') ? 'hamburger__link--active' : ''}`}>
                                    Načelnici
                                </div>
                            </Link>
                            <Link to="/contact">
                                <div className={`hamburger__link ${activePath.startsWith('/contact') ? 'hamburger__link--active' : ''}`}>
                                    Kontakt
                                </div>
                            </Link>
                        </div>
                        
                        {categories?.length ?
                            <React.Fragment>
                                <h1 className="hamburger__titles">
                                    Kategorije
                                </h1>
                                <div className="hamburger__links">
                                    {categories.map(category => {
                                        return <div onClick={() => { this.props.setActiveCategory(category.title); this.setHamburgerOpen(false) }} key={category._id} className={`hamburger__link ${activeCategory === category.title ? 'hamburger__link--active' : ''}`}>
                                            {category.title}
                                        </div>
                                    })}
                                </div>
                            </React.Fragment>
                        : null}
                    
                    </div>
                </div>
            </div>
        );
    }

    setHamburgerOpen = (isOpen) => {
        this.setState({
            open: isOpen
        })
    }
}
const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        region: state.common.region,
        categories: state.blog.categories,
        activeCategory: state.blog.activeCategory
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => { dispatch(logout()) },
        setActiveCategory: (category) => { dispatch(setActiveCategory(category)) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileHeader));