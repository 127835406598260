import React from 'react';
import { connect } from 'react-redux';
import history from '../../store/router/history';

import DashboardMenu from '../Layout/DashboardMenu';
import DashboardHeader from '../Layout/DashboardHeader';


const DashboardLayout = (Component) => {
    
    class Layout extends React.Component {
        render(){
            const { settings } = this.props;
            return (
                <div className="dashboard">
                    <DashboardMenu />
                    <div className="dashboard__content">
                        <DashboardHeader />
                        <div className={`${history.location.pathname.startsWith('/dashboard/messages') ? 'dashboard__content__container-message': 'dashboard__content__container'} content-container ${settings.theme === 'dark' ? 'dashboard__content__container--dark' : ''}`}>
                        <Component />
                        </div>
                    </div>
                </div>
            );
        }
    }
    
    return connect(mapStateToProps)(Layout);
}
const mapStateToProps = (state) => {
    return {
        settings: state.dashboard.settings
    }
}
export default DashboardLayout;