import { config } from '../config';

export const createQueryParamsForMayorsApi = (mayorFilters, page) => {

    const limit = `limit=${config.dogsPerRequest}&`;
    const currentPage = `page=${page ? page : 1}&`
    const entity = mayorFilters?.entity ? `entity=${mayorFilters.entity}&` : '';
    const municipalities = mayorFilters ? createArrayQueryParam(mayorFilters, 'municipalities') : '';
    
    let queryStrings = `${entity + municipalities + limit + currentPage}`;
    queryStrings = queryStrings.slice(0, -1);

    return queryStrings;
}

export const createQueryParamsForMunicipalitiesApi = (municipalityFilters, page) => {
    const limit = `limit=${config.municipalitiesPerRequest}&`;
    const currentPage = `page=${page ? page : 1}&`
    const entity = municipalityFilters?.entity ? `entity=${municipalityFilters.entity}&` : '';
    const cantons = municipalityFilters ? createArrayQueryParam(municipalityFilters, 'cantons') : '';
    let queryStrings = `${limit + currentPage + entity + cantons}`;
    queryStrings = queryStrings.slice(0, -1);

    return queryStrings;
}
export const createPostObjectForApi = (title, suggested, isPublished, content, municipality, municipalityName, mayor, mayorName, category, description, images, mainImage, deletedImages) => {
    const post = new FormData();
    post.append('title', title);
    post.append('isPublished', isPublished);
    post.append('description', description);
    post.append('content', content);
    post.append('municipality', municipality);
    post.append('municipalityName', municipalityName);
    post.append('mayor', mayor);
    post.append('mayorName', mayorName);
    post.append('category', category);
    post.append('main_image', mainImage);
    post.append('suggested', suggested);

    if (images?.length) {
        images.forEach((image, i) => {
            post.append(`images`, image);
        });
    }
    if (deletedImages) {
        deletedImages.forEach((image, i) => {
            post.append(`deleted_images[${i}]`, image);
        });
    }

    return post;
}
export const createCategoryObjectForApi = (title) => {
    const category = new FormData();
    category.append('title', title);

    return category;
}

export const createMunicipalityObjectForApi = (name, surface, settlements, population, entity, zipcode, website, subscribed, mayor, mayorName, canton, cantonName, geography, people, industry, culture, sport, history, images, mainImage, deletedImages) => {
    const municipality = new FormData();
    municipality.append('name', name);
    municipality.append('surface', surface);
    municipality.append('settlements', settlements);
    municipality.append('population', population);
    municipality.append('entity', entity);
    municipality.append('zipcode', zipcode);
    municipality.append('website', website);
    municipality.append('subscribed', subscribed);
    if(mayor){
        municipality.append('mayor', mayor);
        municipality.append('mayorName', mayorName);
    }
    if(canton){
        municipality.append('canton', canton);
        municipality.append('cantonName', cantonName);
    }
    municipality.append('geography', geography);
    municipality.append('people', people);
    municipality.append('industry', industry);
    municipality.append('culture', culture);
    municipality.append('sport', sport);
    municipality.append('history', history);
    municipality.append('main_image', mainImage);

    if (images?.length) {
        images.forEach((image, i) => {
            municipality.append(`images`, image);
        });
    }
    if (deletedImages) {
        deletedImages.forEach((image, i) => {
            municipality.append(`deleted_images[${i}]`, image);
        });
    }
    return municipality;
}
export const createMayorObjectForApi = (firstName, lastName, subscribed, description, facebook, instagram, twitter, entity, canton, cantonName, municipality, municipalityName, images, mainImage, deletedImages) => {
    const mayor = new FormData();
    mayor.append('firstName', firstName);
    mayor.append('lastName', lastName);
    mayor.append('subscribed', subscribed);
    mayor.append('description', description);
    mayor.append('entity', entity);
    mayor.append('facebook', facebook);
    mayor.append('instagram', instagram);
    mayor.append('twitter', twitter);
    mayor.append('municipality', municipality);
    mayor.append('municipalityName', municipalityName);
    mayor.append('canton', canton);
    mayor.append('cantonName', cantonName);
    mayor.append('main_image', mainImage);

    if (images?.length) {
        images.forEach((image, i) => {
            mayor.append(`images`, image);
        });
    }
    if (deletedImages) {
        deletedImages.forEach((image, i) => {
            mayor.append(`deleted_images[${i}]`, image);
        });
    }
    return mayor;
}
export const createUserObjectForApi = (firstName, lastName, username, email, password, role) => {
    const user = new FormData();
    user.append('firstName', firstName);
    user.append('lastName', lastName);
    user.append('username', username);
    user.append('email', email);
    user.append('password', password);
    user.append('role', role);

    return user;
}
const createArrayQueryParam = (object, objectEntity) => {
    let finalOutput = '';

    if(object[objectEntity] && object[objectEntity].length){
        object[objectEntity].forEach(entity => {
            finalOutput += (`${entity},`);
        })
    }

    if(finalOutput) finalOutput = finalOutput.slice(0, -1);

    const queryArray = finalOutput ? `${objectEntity}=${finalOutput}&` : '';

    return queryArray;
}

export const createArrayFormData = (data, items, fieldName) => {
    items.forEach((item, i) => {
        data.append(`${fieldName}[${i}]`, item);
    });
}