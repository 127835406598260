import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import logo from '../../assets/images/opcinaLogoWhite.svg';
import opstinaLogo from '../../assets/images/opstinaLogoWhite.svg';

import facebook from '../../assets/icons/facebook-footer.svg';
import instagram from '../../assets/icons/instagram-footer.svg';
import twitter from '../../assets/icons/twitter-footer.svg';

function Footer({ region }) {

    return (
        <div className="footer">
            <div className="footer__section1">
                {region ? <img src={region === 'FB' ? logo : opstinaLogo} alt="logo" className="footer__section1__logo" /> : null}
                <div className="footer__section1__social">
                    <a rel="noopener noreferrer" target="_Blank"  href="https://www.facebook.com/Op%C4%87inaba-100317631832252">
                        <img src={facebook} alt="facebook" className="footer__section1__social--facebook" />
                    </a>
                    
                    <img src={instagram} alt="instagram" className="footer__section1__social--instagram" />
                    <img src={twitter} alt="twitter" className="footer__section1__social--twitter" />
                </div>
                <h5 className="footer__section1__title">
                    {moment().format('Y')} {region === 'FB' ? 'Općina.ba' : 'Opština.ba'}, Sva prava rezervisana
                </h5>
            </div>
            <div className="footer__section2">
                <h3 className="footer__title">
                    Navigacija
                </h3>
                <Link to="/">
                    <h5 className="footer__link">
                        Blog
                    </h5>
                </Link>
                {region ?
                    <Link to="/municipalities">
                        <h5 className="footer__link">
                            {region === 'FB' ? 'Općine' : 'Opštine'}
                        </h5>
                    </Link>
                : null}

                <Link to="/mayors">
                    <h5 className="footer__link">
                        Načelnici
                    </h5>
                </Link>

                <Link to="/contact">
                    <h5 className="footer__link">
                        Kontakt
                    </h5>
                </Link>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        region: state.common.region
    }
}

export default connect(mapStateToProps)(withRouter(Footer));