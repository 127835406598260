import {
    REGISTRATION_IN_PROGRESS,
    REGISTRATION_COMPLETED,
    SET_USER_INFORMATION,
    STORE_JWT_TOKEN,
    LOGIN_IN_PROGRESS,
    LOGIN_COMPLETED,
    LOGOUT_USER,
    SEND_RESET_PASSSWORD_IN_PROGRESS,
    SEND_RESET_PASSSWORD_COMPLETED,
    RESET_EMAIL_SENT,
    RESET_PASSWORD_IN_PROGRESS,
    RESET_PASSWORD_COMPLETED,
    AUTH_401_ERROR,
    AUTH_404_ERROR,
    AUTH_500_ERROR,
    REMOVE_AUTH_ERRORS,
    RESET_PASSWORD_MODAL_OPEN,
    REGISTRATION_MODAL_OPEN,
    STORE_REFRESH_TOKEN,
    STORE_USER_NOTIFICATION,
    FETCHING_USER_INFO_IN_PROGRESS,
    FETCHING_USER_INFO_COMPLETED,
    STORE_USER_ACHIEVEMENTS,
    FETCHING_USERS_IN_PROGRESS,
    FETCHING_USERS_COMPLETED,
    STORE_FETCHED_USERS,
    DELETING_USER_IN_PROGRESS,
    DELETING_USER_COMPLETED,
    EDITING_USER_IN_PROGRESS,
    EDITING_USER_COMPLETED
} from '../constants';


const initState = {
    isLoggedIn: false,
    registering: false,
    loggingIn: false,
    sendingResetEmail: false,
    resetEmailSent: false,
    resettingPassword: false,
    user: {},
    userId: null,
    token: null,
    refreshToken: null,
    error: null,
    resetPasswordModalOpen: false,
    registrationModalOpen: false,
    notifications: [],
    fetchingUserInfo: false,
    fetchingAllUsers: false,
    users: [],
    deletingUser: false,
    editingUser: false
}

const authReducer = (state = initState, action) => {

    switch (action.type) {
        case REGISTRATION_IN_PROGRESS: {
            return {
                ...state,
                registering: true
            }
        }

        case REGISTRATION_COMPLETED: {
            return {
                ...state,
                registering: false
            }
        }

        case SET_USER_INFORMATION: {
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                userId: action.payload.userId,
                isLoggedIn: true
            }
        }

        case STORE_JWT_TOKEN: {
            return {
                ...state,
                token: action.payload
            }
        }

        case LOGIN_IN_PROGRESS: {
            return {
                ...state,
                loggingIn: true
            }
        }

        case LOGIN_COMPLETED: {
            return {
                ...state,
                loggingIn: false
            }
        }

        case LOGOUT_USER: {
            return {
                ...state,
                isLoggedIn: false,
                user: {},
                userId: null,
                token: null,
                refreshToken: null,
                unlockedAchievements: [],
                lockedAchievements: [],
                notifications: []
            }
        }

        case SEND_RESET_PASSSWORD_IN_PROGRESS: {
            return {
                ...state,
                sendingResetEmail: true
            }
        }

        case SEND_RESET_PASSSWORD_COMPLETED: {
            return {
                ...state,
                sendingResetEmail: false
            }
        }

        case RESET_EMAIL_SENT: {
            return {
                ...state,
                resetEmailSent: true
            }
        }
        
        case RESET_PASSWORD_IN_PROGRESS: {
            return {
                ...state,
                resettingPassword: true
            }
        }

        case RESET_PASSWORD_COMPLETED: {
            return {
                ...state,
                resettingPassword: false
            }
        }

        case AUTH_401_ERROR: {
            return {
                ...state,
                error: 401,
                errorData:  action.payload
            }
        }

        case AUTH_404_ERROR: {
            return {
                ...state,
                error: 404
            }
        }

        case AUTH_500_ERROR: {
            return {
                ...state,
                error: 500
            }
        }

        case REMOVE_AUTH_ERRORS: {
            return {
                ...state,
                error: null,
                errorData: null
            }
        }

        case RESET_PASSWORD_MODAL_OPEN: {
            return {
                ...state,
                resetPasswordModalOpen: action.payload
            }
        }

        case REGISTRATION_MODAL_OPEN: {
            return {
                ...state,
                registrationModalOpen: action.payload
            }
        }

        case STORE_REFRESH_TOKEN: {
            return {
                ...state,
                refreshToken: action.payload
            }
        }

        case STORE_USER_NOTIFICATION: {
            return {
                ...state,
                notifications: [...state.notifications, action.payload]
            }
        }
        case FETCHING_USER_INFO_IN_PROGRESS: {
            return {
                ...state,
                fetchingUserInfo: true
            }
        }
        case FETCHING_USER_INFO_COMPLETED: {
            return {
                ...state,
                fetchingUserInfo: false
            }
        }
        case STORE_USER_ACHIEVEMENTS: {
            return {
                ...state,
                unlockedAchievements: action.payload.unlockedAchievements,
                lockedAchievements: action.payload.lockedAchievements
            }
        }
        case FETCHING_USERS_IN_PROGRESS: {
            return {
                ...state,
                fetchingAllUsers: true
            }
        }
        case FETCHING_USERS_COMPLETED: {
            return {
                ...state,
                fetchingAllUsers: false
            }
        }
        case STORE_FETCHED_USERS: {
            return {
                ...state,
                users: action.payload
            }
        }
        case DELETING_USER_IN_PROGRESS: {
            return {
                ...state,
                deletingUser: true
            }
        }
        case DELETING_USER_COMPLETED: {
            return {
                ...state,
                deletingUser: false
            }
        }
        case EDITING_USER_IN_PROGRESS: {
            return {
                ...state,
                editingUser: true
            }
        }
        case EDITING_USER_COMPLETED: {
            return {
                ...state,
                editingUser: true
            }
        }
        default: {
            return state;
        }
    }
}

export default authReducer;