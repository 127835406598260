import {
    REGISTRATION_IN_PROGRESS,
    REGISTRATION_COMPLETED,
    SET_USER_INFORMATION,
    STORE_JWT_TOKEN,
    LOGIN_IN_PROGRESS,
    LOGIN_COMPLETED,
    LOGOUT_USER,
    SEND_RESET_PASSSWORD_IN_PROGRESS,
    SEND_RESET_PASSSWORD_COMPLETED,
    RESET_EMAIL_SENT,
    RESET_PASSWORD_IN_PROGRESS,
    RESET_PASSWORD_COMPLETED,
    AUTH_401_ERROR,
    AUTH_404_ERROR,
    AUTH_500_ERROR,
    REMOVE_AUTH_ERRORS,
    RESET_PASSWORD_MODAL_OPEN,
    REGISTRATION_MODAL_OPEN,
    STORE_REFRESH_TOKEN,
    STORE_USER_NOTIFICATION,
    FETCHING_USER_INFO_IN_PROGRESS,
    FETCHING_USER_INFO_COMPLETED,
    STORE_USER_ACHIEVEMENTS,
    FETCHING_USERS_IN_PROGRESS,
    FETCHING_USERS_COMPLETED,
    STORE_FETCHED_USERS,
    DELETING_USER_IN_PROGRESS,
    DELETING_USER_COMPLETED,
    EDITING_USER_IN_PROGRESS,
    EDITING_USER_COMPLETED
} from '../constants';

export const ACTION_REGISTRATION_IN_PROGRESS = () => { return { type: REGISTRATION_IN_PROGRESS } };
export const ACTION_REGISTRATION_COMPLETED = () => { return { type: REGISTRATION_COMPLETED } };
export const ACTION_REGISTRATION_MODAL_OPEN = (isOpen) => { return { type: REGISTRATION_MODAL_OPEN, payload: isOpen } };
export const ACTION_LOGIN_IN_PROGRESS = () => { return { type: LOGIN_IN_PROGRESS } };
export const ACTION_LOGIN_COMPLETED = () => { return { type: LOGIN_COMPLETED } };
export const ACTION_SET_USER_INFORMATION = (user, token, userId) => { return { type: SET_USER_INFORMATION, payload: { user: user, token: token, userId } } };
export const ACTION_STORE_JWT_TOKEN = (token) => { return { type: STORE_JWT_TOKEN, payload: token } };
export const ACTION_STORE_REFRESH_TOKEN = (refreshToken) => { return { type: STORE_REFRESH_TOKEN, payload: refreshToken } };
export const ACTION_LOGOUT_USER = () => { return { type: LOGOUT_USER } };
export const ACTION_SEND_RESET_PASSSWORD_IN_PROGRESS = () => { return { type: SEND_RESET_PASSSWORD_IN_PROGRESS } };
export const ACTION_SEND_RESET_PASSSWORD_COMPLETED = () => { return { type: SEND_RESET_PASSSWORD_COMPLETED } };
export const ACTION_RESET_EMAIL_SENT = () => { return { type: RESET_EMAIL_SENT } };
export const ACTION_RESET_PASSWORD_MODAL_OPEN = (isOpen) => { return { type: RESET_PASSWORD_MODAL_OPEN, payload: isOpen } };

export const ACTION_RESET_PASSWORD_IN_PROGRESS = () => { return { type: RESET_PASSWORD_IN_PROGRESS } };
export const ACTION_RESET_PASSWORD_COMPLETED = () => { return { type: RESET_PASSWORD_COMPLETED } };

export const ACTION_AUTH_401_ERROR = (data) => { return { type: AUTH_401_ERROR, payload: data } };
export const ACTION_AUTH_404_ERROR = () => { return { type: AUTH_404_ERROR } };
export const ACTION_AUTH_500_ERROR = () => { return { type: AUTH_500_ERROR } };
export const ACTION_REMOVE_AUTH_ERRORS = () => { return { type: REMOVE_AUTH_ERRORS } };

export const ACTION_STORE_USER_NOTIFICATION = (notification) => { return { type: STORE_USER_NOTIFICATION, payload: notification } };

export const ACTION_FETCHING_USER_INFO_IN_PROGRESS = () => { return { type: FETCHING_USER_INFO_IN_PROGRESS } };
export const ACTION_FETCHING_USER_INFO_COMPLETED = () => { return { type: FETCHING_USER_INFO_COMPLETED } };
export const ACTION_STORE_USER_ACHIEVEMENTS = (unlockedAchievements, lockedAchievements) => {
    return {
        type: STORE_USER_ACHIEVEMENTS, 
        payload: {
            unlockedAchievements,
            lockedAchievements 
        } 
    } 
};

export const ACTION_FETCHING_USERS_IN_PROGRESS = () => { return { type: FETCHING_USERS_IN_PROGRESS } };
export const ACTION_FETCHING_USERS_COMPLETED = () => { return { type: FETCHING_USERS_COMPLETED } };
export const ACTION_STORE_FETCHED_USERS = (users) => { return { type: STORE_FETCHED_USERS, payload: users } };

export const ACTION_DELETING_USER_IN_PROGRESS = () => { return { type: DELETING_USER_IN_PROGRESS } };
export const ACTION_DELETING_USER_COMPLETED = () => { return { type: DELETING_USER_COMPLETED } };

export const ACTION_EDITING_USER_IN_PROGRESS = () => { return { type: EDITING_USER_IN_PROGRESS } };
export const ACTION_EDITING_USER_COMPLETED = () => { return { type: EDITING_USER_COMPLETED } };