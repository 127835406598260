import {
    STORE_USER_MAYORS,
    SET_SETTINGS_PAGE,
    SET_DASHBOARD_SETTINGS,
    CLEAR_DASHBOARD_REDUCER
} from '../constants';

//? Actions for handling general dashboard state
export const ACTION_STORE_USER_MAYORS = (mayors) => { return { type: STORE_USER_MAYORS, payload: mayors } };
export const ACTION_SET_SETTINGS_PAGE = (page) => { return { type: SET_SETTINGS_PAGE, payload: page } };
export const ACTION_SET_DASHBOARD_SETTINGS = (settings) => { return { type: SET_DASHBOARD_SETTINGS, payload: settings } };
export const ACTION_CLEAR_DASHBOARD_REDUCER = () => { return { type: CLEAR_DASHBOARD_REDUCER } };