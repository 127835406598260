import {
    FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS,
    FETCHING_MAIN_BLOG_POSTS_COMPLETED,
    STORE_MAIN_BLOG_POSTS,
    STORE_BLOG_CATEGORIES,
    SET_ACTIVE_CATEGORY,
    FETCHING_CATEGORY_POSTS_IN_PROGRESS,
    FETCHING_CATEGORY_POSTS_COMPLETED,
    STORE_FETCHED_POSTS,
    SET_BLOG_PAGE,
    STORE_ADDITIONAL_POSTS,
    CLEAR_BLOG_STATE,
    FETCHING_ALL_POSTS_IN_PROGRESS,
    STORE_FETCHED_ALL_POSTS,
    FETCHING_ALL_POSTS_COMPLETED,
    FETCHING_ALL_CATEGORIES_IN_PROGRESS,
    STORE_FETCHED_ALL_CATEGORIES,
    FETCHING_ALL_CATEGORIES_COMPLETED,
    CREATING_POST_IN_PROGRESS,
    STORE_CREATED_POST,
    CREATING_POST_COMPLETED,
    FETCHING_POST_OPTIONS_IN_PROGRESS,
    FETCHING_POST_OPTIONS_COMPLETED,
    STORE_POST_OPTIONS,
    EDITING_POST_IN_PROGRESS,
    EDITING_POST_COMPLETED,
    DELETING_POST_IN_PROGRESS,
    DELETING_POST_COMPLETED,
    DELETING_CATEGORY_IN_PROGRESS,
    DELETING_CATEGORY_COMPLETED,
    CREATING_CATEGORY_IN_PROGRESS,
    CREATING_CATEGORY_COMPLETED,
    EDITING_CATEGORY_IN_PROGRESS,
    EDITING_CATEGORY_COMPLETED,
    FETCHING_SINGLE_POST_IN_PROGRESS,
    FETCHING_SINGLE_POST_COMPLETED,
    STORE_FETCHED_POST,
    FETCHING_SEARCH_POSTS_IN_PROGRESS,
    FETCHING_SEARCH_POSTS_COMPLETED,
    STORE_SEARCH_POSTS
} from '../constants';

export const ACTION_FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS = () => { return { type: FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS } };
export const ACTION_FETCHING_MAIN_BLOG_POSTS_COMPLETED = () => { return { type: FETCHING_MAIN_BLOG_POSTS_COMPLETED } };
export const ACTION_STORE_MAIN_BLOG_POSTS = (mainPosts, posts) => { return { type: STORE_MAIN_BLOG_POSTS, payload: { mainPosts: mainPosts, posts: posts} } };
export const ACTION_STORE_BLOG_CATEGORIES = (categories) => { return { type: STORE_BLOG_CATEGORIES, payload: categories } };
export const ACTION_SET_ACTIVE_CATEGORY = (category) => { return { type: SET_ACTIVE_CATEGORY, payload: category } };
export const ACTION_FETCHING_CATEGORY_POSTS_IN_PROGRESS = () => { return { type: FETCHING_CATEGORY_POSTS_IN_PROGRESS } };
export const ACTION_FETCHING_CATEGORY_POSTS_COMPLETED = () => { return { type: FETCHING_CATEGORY_POSTS_COMPLETED } };
export const ACTION_STORE_FETCHED_POSTS = (posts, items) => { return { type: STORE_FETCHED_POSTS, payload: { posts, items } } };
export const ACTION_SET_BLOG_PAGE = (page) => { return { type: SET_BLOG_PAGE, payload: page } };
export const ACTION_STORE_ADDITIONAL_POSTS = (posts) => { return { type: STORE_ADDITIONAL_POSTS, payload: posts } };
export const ACTION_CLEAR_BLOG_STATE = () => { return { type: CLEAR_BLOG_STATE } };

export const ACTION_FETCHING_ALL_POSTS_IN_PROGRESS = () => { return { type: FETCHING_ALL_POSTS_IN_PROGRESS } };
export const ACTION_STORE_FETCHED_ALL_POSTS = (posts) => { return { type: STORE_FETCHED_ALL_POSTS, payload: posts } };
export const ACTION_FETCHING_ALL_POSTS_COMPLETED = () => { return { type: FETCHING_ALL_POSTS_COMPLETED } };

export const ACTION_FETCHING_ALL_CATEGORIES_IN_PROGRESS = () => { return { type: FETCHING_ALL_CATEGORIES_IN_PROGRESS } };
export const ACTION_STORE_FETCHED_ALL_CATEGORIES = (categories) => { return { type: STORE_FETCHED_ALL_CATEGORIES, payload: categories } };
export const ACTION_FETCHING_ALL_CATEGORIES_COMPLETED = () => { return { type: FETCHING_ALL_CATEGORIES_COMPLETED } };

export const ACTION_CREATING_POST_IN_PROGRESS = () => { return { type: CREATING_POST_IN_PROGRESS } };
export const ACTION_STORE_CREATED_POST = (post) => { return { type: STORE_CREATED_POST, payload: post } };
export const ACTION_CREATING_POST_COMPLETED = () => { return { type: CREATING_POST_COMPLETED } };

export const ACTION_FETCHING_POST_OPTIONS_IN_PROGRESS = () => { return { type: FETCHING_POST_OPTIONS_IN_PROGRESS } };
export const ACTION_FETCHING_POST_OPTIONS_COMPLETED = () => { return { type: FETCHING_POST_OPTIONS_COMPLETED } };
export const ACTION_STORE_POST_OPTIONS = (options) => { return { type: STORE_POST_OPTIONS, payload: options } };

export const ACTION_EDITING_POST_IN_PROGRESS = () => { return { type: EDITING_POST_IN_PROGRESS } };
export const ACTION_EDITING_POST_COMPLETED = () => { return { type: EDITING_POST_COMPLETED } };

export const ACTION_DELETING_POST_IN_PROGRESS = () => {return { type: DELETING_POST_IN_PROGRESS } };
export const ACTION_DELETING_POST_COMPLETED = () => {return { type: DELETING_POST_COMPLETED } };

export const ACTION_DELETING_CATEGORY_IN_PROGRESS = () => { return { type: DELETING_CATEGORY_IN_PROGRESS } };
export const ACTION_DELETING_CATEGORY_COMPLETED = () => { return { type: DELETING_CATEGORY_COMPLETED } };

export const ACTION_CREATING_CATEGORY_IN_PROGRESS = () => { return { type: CREATING_CATEGORY_IN_PROGRESS } };
export const ACTION_CREATING_CATEGORY_COMPLETED = () => { return { type: CREATING_CATEGORY_COMPLETED } };

export const ACTION_EDITING_CATEGORY_IN_PROGRESS = () => { return { type: EDITING_CATEGORY_IN_PROGRESS } };
export const ACTION_EDITING_CATEGORY_COMPLETED = () => { return { type: EDITING_CATEGORY_COMPLETED } };

export const ACTION_FETCHING_SINGLE_POST_IN_PROGRESS = () => { return { type: FETCHING_SINGLE_POST_IN_PROGRESS } };
export const ACTION_FETCHING_SINGLE_POST_COMPLETED = () => { return { type: FETCHING_SINGLE_POST_COMPLETED } };
export const ACTION_STORE_FETCHED_POST = (post, relatedPosts, newestPosts, popularPosts) => { return { type: STORE_FETCHED_POST, payload: { post, relatedPosts, newestPosts, popularPosts } } };

export const ACTION_FETCHING_SEARCH_POSTS_IN_PROGRESS = () => { return { type: FETCHING_SEARCH_POSTS_IN_PROGRESS } };
export const ACTION_FETCHING_SEARCH_POSTS_COMPLETED = () => { return { type: FETCHING_SEARCH_POSTS_COMPLETED } };
export const ACTION_STORE_SEARCH_POSTS = (searchPosts) => { return { type: STORE_SEARCH_POSTS, payload: searchPosts } };