import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Router } from 'react-router';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import FrontendLayout from './components/hocs/FrontendLayout';
import DashboardLayout from './components/hocs/DashboardLayout';
import ProtectedRoute from './components/hocs/ProtectedRoute';
import history from './store/router/history';
import { checkAndSetSessionId } from './store/actions/commonActions';

const MayorsPage = lazy( () => import('./components/MayorsPage/MayorsPage')); 
const LoginPage  = lazy( () => import('./components/LoginPage/LoginPage'));
const BlogPage = lazy( () => import('./components/BlogPage/BlogPage')); 
const MunicipalitiesPage = lazy(() => import('./components/MunicipalitiesPage/MunicipalitiesPage')); 
const Error404 = lazy(() => import('./components/ErrorPages/Error404'));
const DashboardPage = lazy(() => import('./components/DashboardPage/DashboardPage'));
const MayorPage = lazy(() => import('./components/MayorPage/MayorPage'));
const MunicipalityPage = lazy(() => import('./components/MunicipalityPage/MunicipalityPage'));

const ContactPage = lazy(() => import('./components/ContactPage/ContactPage'));
const SettingsPage = lazy(() => import('./components/DashboardPage/SettingsPage/SettingsPage'));
const UsersPage = lazy(() => import('./components/DashboardPage/UsersPage/UsersPage'));
const DashboardMunicipalitiesPage = lazy(() => import('./components/DashboardPage/MunicipalitiesPage/MunicipalitiesPage')) ;
const DashboardMayorsPage = lazy(() => import('./components/DashboardPage/MayorsPage/MayorsPage')) ;
const PostsPage = lazy(() => import('./components/DashboardPage/PostsPage/PostsPage')); 
const CategoriesPage = lazy(() => import('./components/DashboardPage/CategoriesPage/CategoriesPage')); 
const CreatePostForm = lazy(() => import('./components/DashboardPage/PostsPage/CreatePostForm')); 
const EditPostForm = lazy(() => import('./components/DashboardPage/PostsPage/EditPostForm')); 
const CreateUserForm = lazy(() => import('./components/DashboardPage/UsersPage/CreateUserForm')); 
const EditUserForm = lazy(() => import('./components/DashboardPage/UsersPage/EditUserForm')); 
const CreateCategoryForm = lazy(() => import('./components/DashboardPage/CategoriesPage/CreateCategoryForm')); 
const EditCategoryForm = lazy(() => import('./components/DashboardPage/CategoriesPage/EditCategoryForm')); 
const EditMunicipalityForm = lazy(() => import('./components/DashboardPage/MunicipalitiesPage/EditMunicipalityForm')); 
const CreateMunicipalityForm = lazy(() => import('./components/DashboardPage/MunicipalitiesPage/CreateMunicipalityForm')); 
const CreateMayorForm = lazy(() => import('./components/DashboardPage/MayorsPage/CreateMayorForm')); 
const EditMayorForm = lazy(() => import('./components/DashboardPage/MayorsPage/EditMayorForm')); 
const PostPage = lazy(() => import('./components/PostPage/PostPage'));


class App extends Component {

  componentDidMount() {
    const { checkAndSetSessionId } = this.props;
    window.prerenderReady = false;
    checkAndSetSessionId();
  }
  shouldComponentUpdate(nextProps){
    if(this.props.region !== nextProps.region)
      return true;

    return false;
  }
  render() {
    return (
      <Router history={history}>
        <Helmet>
          <meta name="description" content={`${this.props.region === 'FB' ? 'Općina' : 'Opština'}.ba portal za političke vijesti`} />
          <title>{this.props.region === 'FB' ? 'Općina' : 'Opština'}.ba</title>
          
          <meta property="og:type" content="website" />
          <meta name="og:locale" content="bs" />
          <meta name="og:sitename" content={`${this.props.region === 'FB' ? 'Općina' : 'Opština'}.ba`} />
        </Helmet>
        <Suspense fallback={<React.Fragment/>}>
          <Switch>
            <Route exact path="/contact" component={FrontendLayout(ContactPage)} />

            <Route exact path="/municipalities/:municipalityId" component={FrontendLayout(MunicipalityPage)} />
            <Route exact path="/municipalities" component={FrontendLayout(MunicipalitiesPage)} />
            <Route exact path="/mayors/:mayorId" component={FrontendLayout(MayorPage)} />
            <Route exact path="/mayors" component={FrontendLayout(MayorsPage)} />
            <Route exact path="/" component={FrontendLayout(BlogPage)} />
            <Route exact path="/dashboard/login" component={FrontendLayout(LoginPage)} />
            <Route exact path="/post/:postId" component={FrontendLayout(PostPage)} />

            <ProtectedRoute exact path="/dashboard" component={DashboardLayout(DashboardPage)} />

            <ProtectedRoute exact path="/dashboard/users" component={DashboardLayout(UsersPage)} />
            <ProtectedRoute exact path="/dashboard/users/add" component={DashboardLayout(CreateUserForm)} />
            <ProtectedRoute exact path="/dashboard/users/edit" component={DashboardLayout(EditUserForm)} />

            <ProtectedRoute exact path="/dashboard/posts/add" component={DashboardLayout(CreatePostForm)} />
            <ProtectedRoute exact path="/dashboard/posts/edit" component={DashboardLayout(EditPostForm)} />
            <ProtectedRoute exact path="/dashboard/posts" component={DashboardLayout(PostsPage)} />

            <ProtectedRoute exact path="/dashboard/municipalities" component={DashboardLayout(DashboardMunicipalitiesPage)} />
            <ProtectedRoute exact path="/dashboard/municipalities/add" component={DashboardLayout(CreateMunicipalityForm)} />
            <ProtectedRoute exact path="/dashboard/municipalities/edit" component={DashboardLayout(EditMunicipalityForm)} />

            <ProtectedRoute exact path="/dashboard/categories" component={DashboardLayout(CategoriesPage)} />
            <ProtectedRoute exact path="/dashboard/categories/add" component={DashboardLayout(CreateCategoryForm)} />
            <ProtectedRoute exact path="/dashboard/categories/edit" component={DashboardLayout(EditCategoryForm)} />

            <ProtectedRoute exact path="/dashboard/mayors" component={DashboardLayout(DashboardMayorsPage)} />
            <ProtectedRoute exact path="/dashboard/mayors/add" component={DashboardLayout(CreateMayorForm)} />
            <ProtectedRoute exact path="/dashboard/mayors/edit" component={DashboardLayout(EditMayorForm)} />

            <ProtectedRoute exact path="/dashboard/settings" component={DashboardLayout(SettingsPage)} />
            <Route component={Error404} />
          </Switch>
        </Suspense>
       
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    region: state.common.region,
    isLoggedIn: state.auth.isLoggedIn,
    userId: state.auth.userId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkAndSetSessionId: () => { dispatch(checkAndSetSessionId()) },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
