import api from '../../api';
import crypto from 'crypto';
import apiInstance from '../../api/apiConfig';
import {
    ACTION_STORE_SESSION_ID,
    ACTION_SENDING_CONTACT_IN_PROGRESS,
    ACTION_SENDING_CONTACT_COMPLETED,
    ACTION_CONTACT_MODAL_OPEN
} from '../actions/commonActionsList';

export const checkAndSetSessionId = () => {
    return async (dispatch, getState) => {
        if(!getState().common.sessionId || !getState().common.region){
            try {
                const response = await api.getIP();
                const region = response.data.region;
                crypto.randomBytes(64, async (err, buffer) => {
                    if (!err) {
                        const sessionId = buffer.toString('hex');
                        dispatch(ACTION_STORE_SESSION_ID(sessionId, region));
                        apiInstance.defaults.headers['SessionId'] = sessionId;
                    }
                });
                
            } catch(error) {
                crypto.randomBytes(64, async (err, buffer) => {
                    if(!err){
                        const sessionId = buffer.toString('hex');
                        dispatch(ACTION_STORE_SESSION_ID(sessionId, 'FB'));
                        apiInstance.defaults.headers['SessionId'] = sessionId;
                    }
                });
            }
        }
    }
}

export const sendContactRequest = (request) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_SENDING_CONTACT_IN_PROGRESS());
            const response = await api.sendContactRequest(request);
            if(response?.data) {
                dispatch(ACTION_CONTACT_MODAL_OPEN(true));
                dispatch(ACTION_SENDING_CONTACT_COMPLETED());
            }
        } catch (error) {
            dispatch(ACTION_SENDING_CONTACT_COMPLETED());
        }
    }
}

export const setContactModalOpen = (isOpen) => {
    return (dispatch) => {
        dispatch(ACTION_CONTACT_MODAL_OPEN(isOpen));
    }
}