import { 
    STORE_SESSION_ID,
    SENDING_CONTACT_IN_PROGRESS,
    SENDING_CONTACT_COMPLETED,
    CONTACT_MODAL_OPEN
} from '../constants';


export const ACTION_STORE_SESSION_ID = (sessionId, region) => { return { type: STORE_SESSION_ID, payload: {sessionId, region} } };

export const ACTION_SENDING_CONTACT_IN_PROGRESS = () => { return { type: SENDING_CONTACT_IN_PROGRESS } };
export const ACTION_SENDING_CONTACT_COMPLETED = () => { return { type: SENDING_CONTACT_COMPLETED } };
export const ACTION_CONTACT_MODAL_OPEN = (isOpen) => { return { type: CONTACT_MODAL_OPEN, payload: isOpen } };