import {
    FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS,
    FETCHING_MAIN_BLOG_POSTS_COMPLETED,
    STORE_MAIN_BLOG_POSTS,
    STORE_BLOG_CATEGORIES,
    SET_ACTIVE_CATEGORY,
    FETCHING_CATEGORY_POSTS_IN_PROGRESS,
    FETCHING_CATEGORY_POSTS_COMPLETED,
    STORE_FETCHED_POSTS,
    SET_BLOG_PAGE,
    STORE_ADDITIONAL_POSTS,
    CLEAR_BLOG_STATE,
    FETCHING_ALL_POSTS_IN_PROGRESS,
    STORE_FETCHED_ALL_POSTS,
    FETCHING_ALL_POSTS_COMPLETED,
    FETCHING_ALL_CATEGORIES_IN_PROGRESS,
    STORE_FETCHED_ALL_CATEGORIES,
    FETCHING_ALL_CATEGORIES_COMPLETED,
    CREATING_POST_IN_PROGRESS,
    STORE_CREATED_POST,
    CREATING_POST_COMPLETED,
    FETCHING_POST_OPTIONS_IN_PROGRESS,
    FETCHING_POST_OPTIONS_COMPLETED,
    STORE_POST_OPTIONS,
    EDITING_POST_IN_PROGRESS,
    EDITING_POST_COMPLETED,
    DELETING_POST_IN_PROGRESS,
    DELETING_POST_COMPLETED,
    DELETING_CATEGORY_IN_PROGRESS,
    DELETING_CATEGORY_COMPLETED,
    CREATING_CATEGORY_IN_PROGRESS,
    CREATING_CATEGORY_COMPLETED,
    EDITING_CATEGORY_IN_PROGRESS,
    EDITING_CATEGORY_COMPLETED,
    FETCHING_SINGLE_POST_IN_PROGRESS,
    FETCHING_SINGLE_POST_COMPLETED,
    STORE_FETCHED_POST,
    FETCHING_SEARCH_POSTS_IN_PROGRESS,
    FETCHING_SEARCH_POSTS_COMPLETED,
    STORE_SEARCH_POSTS
} from '../constants';

const initState = {
    main: {},
    categories: null,
    posts: {},
    fetchingMainPosts: false,
    activeCategory: null,
    fetchingActiveCategoryPosts: false,
    categoryPosts: null,
    categoryItems: null,
    categoryPage: 1,
    dashboardPosts: [],
    fetchingAllPosts: false,
    dashboardCategories: [],
    fetchingAllCategories: false,
    creatingPost: false,
    postOptions: {
        categories: [],
        mayors: [],
        municipalities: []
    },
    fetchingPostOptions: false,
    editingPost: false,
    deletingPost: false,
    deletingCategory: false,
    creatingCategory: false,
    editingCategory: false,
    fetchingPost: false,
    post: {},
    relatedPosts: [],
    newestPosts: [],
    popularPosts: [],
    searchPosts: [],
    fetchingSearchPosts: false
}

const blogReducer = (state = initState, action) => {

    switch (action.type) {

        case FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS: {
            return {
                ...state,
                fetchingMainPosts: true
            }
        }

        case FETCHING_MAIN_BLOG_POSTS_COMPLETED: {
            return {
                ...state,
                fetchingMainPosts: false
            }
        }

        case STORE_MAIN_BLOG_POSTS: {
            return {
                ...state,
                main: action.payload.mainPosts,
                posts: action.payload.posts
            }
        }

        case STORE_BLOG_CATEGORIES: {
            return {
                ...state,
                categories: action.payload
            }
        }

        case SET_ACTIVE_CATEGORY: {
            return {
                ...state,
                activeCategory: action.payload
            }
        }

        case FETCHING_CATEGORY_POSTS_IN_PROGRESS: {
            return {
                ...state,
                fetchingActiveCategoryPosts: true   
            }
        }

        case FETCHING_CATEGORY_POSTS_COMPLETED: {
            return {
                ...state,
                fetchingActiveCategoryPosts: false
            }
        }
        case STORE_FETCHED_POSTS: {
            return {
                ...state,
                categoryPosts: action.payload.posts,
                categoryItems: action.payload.items
            }
        }
        case SET_BLOG_PAGE: {
            return {
                ...state,
                categoryPage: action.payload
            }
        }
        case STORE_ADDITIONAL_POSTS: {
            return {
                ...state,
                categoryPosts: [...state.categoryPosts, ...action.payload]
            }
        }
        case CLEAR_BLOG_STATE: {
            return initState
        }
        case FETCHING_ALL_POSTS_IN_PROGRESS: {
            return {
                ...state,
                fetchingAllPosts: true
            }
        }
        case STORE_FETCHED_ALL_POSTS: {
            return {
                ...state,
                dashboardPosts: action.payload
            }
        }
        case FETCHING_ALL_POSTS_COMPLETED: {
            return {
                ...state,
                fetchingAllPosts: false
            }
        }
        case FETCHING_ALL_CATEGORIES_IN_PROGRESS: {
            return {
                ...state,
                fetchingAllCategories: true
            }
        }
        case STORE_FETCHED_ALL_CATEGORIES: {
            return {
                ...state,
                dashboardCategories: action.payload
            }
        }
        case FETCHING_ALL_CATEGORIES_COMPLETED: {
            return {
                ...state,
                fetchingAllCategories: false
            }
        }
        case CREATING_POST_IN_PROGRESS: {
            return {
                ...state,
                creatingPost: true
            }
        }
        case STORE_CREATED_POST: {
            return {
                ...state,
                dashboardPosts: [...state.dashboardPosts, action.payload]
            }
        }
        case CREATING_POST_COMPLETED: {
            return {
                ...state,
                creatingPost: false
            }
        }
        case FETCHING_POST_OPTIONS_IN_PROGRESS: {
            return {
                ...state,
                fetchingPostOptions: true
            }
        }
        case FETCHING_POST_OPTIONS_COMPLETED: {
            return {
                ...state,
                fetchingPostOptions: false
            }
        }
        case STORE_POST_OPTIONS: {
            return {
                ...state,
                postOptions: action.payload
            }
        }
        case EDITING_POST_IN_PROGRESS: {
            return {
                ...state,
                editingPost: true
            }
        }
        case EDITING_POST_COMPLETED: {
            return {
                ...state,
                editingPost: false
            }
        }
        case DELETING_POST_IN_PROGRESS: {
            return {
                ...state,
                deletingPost: true
            }
        }
        case DELETING_POST_COMPLETED: {
            return {
                ...state,
                deletingPost: false
            }
        }
        case DELETING_CATEGORY_IN_PROGRESS: {
            return {
                ...state,
                deletingCategory: true
            }
        }
        case DELETING_CATEGORY_COMPLETED: {
            return {
                ...state,
                deletingCategory: false
            }
        }
        case CREATING_CATEGORY_IN_PROGRESS: {
            return {
                ...state,
                creatingCategory: true
            }
        }
        case CREATING_CATEGORY_COMPLETED: {
            return {
                ...state,
                creatingCategory: false
            }
        }
        case EDITING_CATEGORY_IN_PROGRESS: {
            return {
                ...state,
                editingCategory: true
            }
        }
        case EDITING_CATEGORY_COMPLETED: {
            return {
                ...state,
                editingCategory: false
            }
        }
        case FETCHING_SINGLE_POST_IN_PROGRESS: {
            return {
                ...state,
                fetchingPost: true
            }
        }
        case FETCHING_SINGLE_POST_COMPLETED: {
            return {
                ...state,
                fetchingPost: false
            }
        }
        case STORE_FETCHED_POST: {
            return {
                ...state,
                post: action.payload.post,
                relatedPosts: action.payload.relatedPosts,
                newestPosts: action.payload.newestPosts,
                popularPosts: action.payload.popularPosts
            }
        }
        case FETCHING_SEARCH_POSTS_IN_PROGRESS: {
            return {
                ...state,
                fetchingSearchPosts: true
            }
        }
        case FETCHING_SEARCH_POSTS_COMPLETED: {
            return {
                ...state,
                fetchingSearchPosts: false
            }
        }
        case STORE_SEARCH_POSTS: {
            return {
                ...state,
                searchPosts: action.payload
            }
        }
        default: {
            return state;
        }
    }
}

export default blogReducer;