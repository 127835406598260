

import apiInstance from './apiConfig';
import { createQueryParamsForMayorsApi, createQueryParamsForMunicipalitiesApi, createArrayFormData } from '../helpers/apiHelpers';

function getIP() {
    return apiInstance.get('/ip')
}
function sendContactRequest(request) {
    return apiInstance.post('/contact', request);
}
//* -------------------------------------------------------------- //
//*                    AUTHENTICATION ENDPOINTS                    //
//* -------------------------------------------------------------- //
function login(email, username, password) {
    const data = new FormData();
    data.append('email', email);
    data.append('username', username);
    data.append('password', password);

    return apiInstance.post('/auth/login', data);
}

function register(user) {
    return apiInstance.put('/auth/register', user);
}

function sendResetPasswordEmail(email) {
    const data = new FormData();
    data.append('email', email);
    return apiInstance.post('auth/reset-password', data);
}

function resetPassword(password, userId, token) {
    const data = new FormData();
    data.append('password', password);
    data.append('userId', userId);
    data.append('token', token);
    
    return apiInstance.post('/auth/set-password', data);
}

function editProfile(user) {
    return apiInstance.post(`auth/edit-profile/${user._id}`, {
        newsletter: user.newsletter,
        firstName: user.firstName,
        lastName: user.lastName,
        use_location: user.use_location,
        main_image: user.main_image
    })
}

function getDashboardStats() {
    return apiInstance.get('/auth/dashboard');
}

function refreshToken(refreshToken) {
    const data = new FormData();
    data.append('refreshToken', refreshToken);
    return apiInstance.post('/auth/refresh', data);
}

function getUserInfo() {
    return apiInstance.get('/auth/user');
}

function getAllUsers() {
    return apiInstance.get('/auth/users')
}

function deleteUser(id) {
    return apiInstance.delete(`/auth/${id}`);
}
function editUser(user, id) {
    return apiInstance.patch(`/auth/${id}`, user);
}
//* -------------------------------------------------------------- //
//*                        MAYORS ENDPOINTS                        //
//* -------------------------------------------------------------- //

function getMayors(filters, page, type) {
    const queryFilters = createQueryParamsForMayorsApi(filters, page, type);
    
    return apiInstance.get(`/mayors?${queryFilters ? queryFilters : ''}`)
}

function getMayor(id) {
    return apiInstance.get(`/mayors/mayor/${id}`)
}

function createMayor(mayor) {
    return apiInstance.post('/mayors/mayor', mayor)
}

function editMayor(mayor, id) {
    return apiInstance.patch(`/mayors/mayor/${id}`, mayor)
}

function deleteMayor(id) {
    return apiInstance.delete(`/mayors/mayor/${id}`)
}
function getMayorOptions() {
    return apiInstance.get('/mayors/options');
}
function getAllMayors() {
    return apiInstance.get('/mayors/dashboard');
}
//* -------------------------------------------------------------- //
//*                MUNICIPALITIES ENDPOINTS                        //
//* -------------------------------------------------------------- //

function getMunicipalities(filters, page) {
    const queryFilters = createQueryParamsForMunicipalitiesApi(filters, page);
    
    return apiInstance.get(`/municipalities?${queryFilters ? queryFilters : ''}`)
}

function getMunicipality(id) {
    return apiInstance.get(`/municipalities/municipality/${id}`)
}
function getAllMunicipalities() {
    return apiInstance.get('/municipalities/dashboard');
}
function editMunicipality(municipality, id) {
    return apiInstance.patch(`/municipalities/municipality/${id}`, municipality);
}
function getMunicipalityOptions() {
    return apiInstance.get('/municipalities/options');
}
function createMunicipality(municipality) {
    return apiInstance.post('/municipalities/municipality', municipality)
}
function deleteMunicipality(id) {
    return apiInstance.delete(`/municipalities/municipality/${id}`);
}
//* -------------------------------------------------------------- //
//*                       MESSAGE ENDPOINTS                        //
//* -------------------------------------------------------------- //
function sendMessage(author, recipient, message, isMayorInquiry, mayor){
    const data = new FormData();
    data.append('author', author);
    data.append('recipient', recipient);
    data.append('message', message);
    data.append('isMayorInquiry', isMayorInquiry);
    data.append('mayor', mayor);
    return apiInstance.put('/messaging/message', data);
}
function fetchUserConversations(ids) {
    const data = new FormData();
    createArrayFormData(data, ids, 'ids');
    return apiInstance.post('/messaging/conversation', data);
}
function fetchMessages(convId) {
    return apiInstance.get(`/messaging/conversation/${convId}`);
}
function sendChatMessage(message, convId) {
    const data = new FormData();
    data.append('message',message);
    data.append('conversationId', convId);
    return apiInstance.post('/messaging/message', data);
}

function updateUnreadMessages(conversationId, recipientUnreadCount, authorUnreadCount) {
    const data = new FormData();
    data.append('recipientUnreadCount', recipientUnreadCount);
    data.append('authorUnreadCount', authorUnreadCount);
    return apiInstance.post(`/messaging/conversation/${conversationId}`, data);
}


//* -------------------------------------------------------------- //
//*                          BLOG ENDPOINTS                        //
//* -------------------------------------------------------------- //
function mainBlogPosts() {
    return apiInstance.get('/blog');
}
function getPosts(category, page) {
    const data = new FormData();
    data.append('category', category);
    data.append('page', page)
    return apiInstance.post('/blog/posts', data);
}
function getAllPosts() {
    return apiInstance.get('/blog/dashboard');
}
function getAllCategories() {
    return apiInstance.get('/blog/dashboard/categories');
}
function createPost(post) {
    return apiInstance.post('/blog/post', post);
}
function getPostOptions() {
    return apiInstance.get('/blog/options');
}
function editPost(post, id) {
    return apiInstance.patch(`/blog/post/${id}`, post);
}
function deletePost(id) {
    return apiInstance.delete(`/blog/post/${id}`);
}
function deleteCategory(id) {
    return apiInstance.delete(`/blog/category/${id}`);
}
function createCategory(category) {
    return apiInstance.put('/blog/category', category);
}
function editCategory(category, id) {
    return apiInstance.patch(`/blog/category/${id}`, category)
}
function fetchPost(id) {
    return apiInstance.get(`/blog/post/${id}`);
}
function getSearchPosts(search) {
    const data = new FormData();
    data.append('search', search);
    return apiInstance.post('/blog/search', data);
}
const api = {
    getIP,
    login,
    register,
    sendResetPasswordEmail,
    resetPassword,
    editProfile,
    refreshToken,
    getMayors,
    getMayor,
    getMunicipalities,
    getMunicipality,
    getDashboardStats,
    createMayor,
    editMayor,
    deleteMayor,
    getMayorOptions,
    sendMessage,
    fetchUserConversations,
    fetchMessages,
    sendChatMessage,
    updateUnreadMessages,
    getUserInfo,
    mainBlogPosts,
    getPosts,
    getAllUsers,
    getAllMunicipalities,
    getAllMayors,
    getAllPosts,
    getAllCategories,
    createPost,
    getPostOptions,
    editPost,
    deletePost,
    deleteUser,
    editUser,
    deleteCategory,
    createCategory,
    editCategory,
    editMunicipality,
    getMunicipalityOptions,
    createMunicipality,
    deleteMunicipality,
    fetchPost,
    getSearchPosts,
    sendContactRequest
}

export default api;