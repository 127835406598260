import commonReducer from './commonReducer';
import authReducer from './authReducer';
import mayorReducer from './mayorReducer';
import municipalityReducer from './municipalityReducer';
import statisticReducer from './statisticReducer';
import dashboardReducer from './dashboardReducer';
import blogReducer from './blogReducer';

import { combineReducers } from 'redux';

import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    whitelist: ['token','refreshToken', 'isLoggedIn', 'user', 'userId']
}

const commonPersistConfig = {
    key: 'common',
    storage: storage,
    whitelist: ['sessionId', 'region']
}
const dashboardPersistConfig = {
    key: 'dashboard',
    storage: storage,
    whitelist: ['mayors', 'calendar', 'settings']
}
const rootReducer = combineReducers({
    common: persistReducer(commonPersistConfig, commonReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    mayors: mayorReducer,
    dashboard: persistReducer(dashboardPersistConfig, dashboardReducer),
    municipalities: municipalityReducer,
    statistics: statisticReducer,
    blog: blogReducer
})

export default rootReducer;