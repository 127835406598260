import {
    SET_SETTINGS_PAGE,
    SET_DASHBOARD_SETTINGS,
    CLEAR_DASHBOARD_REDUCER
} from '../constants';

const initState = {
    dogs: [],
    creatingDog: false,
    editingDog: false,
    deletingDog: false,
    calendar: [],
    creatingCalendarDate: false,
    editingCalendarDate: false,
    deletingCalendarDate: false,
    settingsPage: 'general',
    settings: {
        theme: 'light',
        dogForm: 'steps'
    }
}

const dashboardReducer = (state = initState, action) => {

    switch (action.type) {

       
        case SET_SETTINGS_PAGE: {
            return {
                ...state,
                settingsPage: action.payload
            }
        }

        case SET_DASHBOARD_SETTINGS: {
            return {
                ...state,
                settings: action.payload
            }
        }

        case CLEAR_DASHBOARD_REDUCER: {
            return initState;
        }
        
        default: {
            return state;
        }
    }
}

export default dashboardReducer;