import { 
    SET_DASHBOARD_STATISTICS,
    FETCHING_DASHBOARD_STATISTICS_IN_PROGRESS,
    FETCHING_DASHBOARD_STATISTICS_COMPLETED
} from '../constants';

const initState = {
    dailyViews: null,
    lastDayViews: null,
    monthlyViews: null,
    weeklyViews: null,
    lastMonthViews: null,
    lastWeekViews: null,
    totalViews: null,
    fetchingDashboardStatistics: false
}

const statisticReducer = (state = initState, action) => {
    switch (action.type) {  
        case SET_DASHBOARD_STATISTICS: {
            return {
                ...state,
                monthlyViews: action.payload.stats.monthlyViews,
                weeklyViews: action.payload.stats.weeklyViews,
                lastMonthViews: action.payload.stats.lastMonthViews,
                lastWeekViews: action.payload.stats.lastWeekViews,
                dailyViews: action.payload.stats.dailyViews,
                lastDayViews: action.payload.stats.lastDayViews,
                totalViews: action.payload.stats.totalViews
            }
        }  
        case FETCHING_DASHBOARD_STATISTICS_IN_PROGRESS: {
            return {
                ...state,
                fetchingDashboardStatistics: true
            }
        }
        case FETCHING_DASHBOARD_STATISTICS_COMPLETED: {
            return {
                ...state,
                fetchingDashboardStatistics: false
            }
        }
        default: {
            return state;
        }
    }
}

export default statisticReducer;