import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Button from '../Global/Button';

class DashboardHeader extends Component {

    render() {
        const { settings } = this.props;



        return (
            <div className={`dashboard-header ${settings.theme === 'dark' ? 'dashboard-header--dark' : ''}`}>
                <h2>
                    {this.getPathName()}
                </h2>
                <div className={`dashboard-header__right ${settings.theme === 'dark' ? 'dashboard-header__right--dark' : ''}`}>
                    <div className="dashboard-header__right__item">
                        <Button onClick={this.goToArticleCreation} classes="dashboard-header__button" text="Dodaj članak &#43;" color="green" />
                    </div>
                    
                    
                </div>
            </div>
        );
    }
   
    toggleMessageModal = () => {
        this.setState({
            messagesModalOpen: !this.state.messagesModalOpen
        })
    }
    
    goToArticleCreation = () => {
        this.props.history.push('/dashboard/posts/add')
    }
    
    getPathName = () => {
        const path = this.props.history.location.pathname;
        if(path.startsWith('/dashboard/settings')){
            return 'Postavke';
        }
        else if(path === '/dashboard/mayors'){
            return 'Načelnici';
        }
        else if (path === ('/dashboard/mayors/add')) {
            return 'Načelnici | Dodavanje načelnika';
        }
        else if (path === ('/dashboard/mayors/edit')) {
            return 'Načelnici | Uređivanje načelnika';
        }
        else if(path === ('/dashboard/municipalities')){
            return 'Općine';
        }
        else if (path === ('/dashboard/municipalities/add')) {
            return 'Općine | Dodavanje općine';
        }
        else if (path === ('/dashboard/municipalities/edit')) {
            return 'Općine | Uređivanje općine';
        }
        else if(path === ('/dashboard/posts')){
            return 'Članci';
        }
        else if (path === ('/dashboard/posts/add')) {
            return 'Članci | Dodavanje novog članka'
        }
        else if (path === ('/dashboard/posts/edit')) {
            return 'Članci | Uređivanje članka'
        }
        else if(path === ('/dashboard/users')){
            return 'Korisnici';
        }
        else if (path === ('/dashboard/users/add')) {
            return 'Korisnici | Dodavanje korisnika';
        }
        else if (path === ('/dashboard/users/edit')) {
            return 'Korisnici | Uređivanje korisnika';
        }
        else 
            return 'Početna';
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userId: state.auth.userId,
        settings: state.dashboard.settings
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardHeader));