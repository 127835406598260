import api from '../../api';

import {  
    ACTION_REGISTRATION_IN_PROGRESS,
    ACTION_REGISTRATION_COMPLETED,
    ACTION_LOGIN_IN_PROGRESS,
    ACTION_LOGIN_COMPLETED,
    ACTION_SET_USER_INFORMATION,
    ACTION_STORE_JWT_TOKEN,
    ACTION_LOGOUT_USER,
    ACTION_SEND_RESET_PASSSWORD_IN_PROGRESS,
    ACTION_SEND_RESET_PASSSWORD_COMPLETED,
    ACTION_RESET_EMAIL_SENT,
    ACTION_RESET_PASSWORD_IN_PROGRESS,
    ACTION_RESET_PASSWORD_COMPLETED,
    ACTION_AUTH_401_ERROR,
    ACTION_AUTH_404_ERROR,
    ACTION_AUTH_500_ERROR,
    ACTION_REMOVE_AUTH_ERRORS,
    ACTION_RESET_PASSWORD_MODAL_OPEN,
    ACTION_REGISTRATION_MODAL_OPEN,
    ACTION_STORE_REFRESH_TOKEN,
    ACTION_FETCHING_USER_INFO_IN_PROGRESS,
    ACTION_FETCHING_USER_INFO_COMPLETED,
    ACTION_STORE_USER_ACHIEVEMENTS,
    ACTION_FETCHING_USERS_IN_PROGRESS,
    ACTION_FETCHING_USERS_COMPLETED,
    ACTION_STORE_FETCHED_USERS,
    ACTION_DELETING_USER_IN_PROGRESS,
    ACTION_DELETING_USER_COMPLETED,
    ACTION_EDITING_USER_IN_PROGRESS,
    ACTION_EDITING_USER_COMPLETED
} from './authActionsList';

import { 
    ACTION_CLEAR_DASHBOARD_REDUCER
} from './dashboardActionsList';
import history from '../router/history';

export const register = (user) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_REGISTRATION_IN_PROGRESS());
            const response = await api.register(user);
            if(response && response.data){
                history.push('/dashboard/users');
            }
            dispatch(ACTION_REGISTRATION_COMPLETED());
        } catch(error) {
            if(error.status === 401){
                const errors = [];
                error.data.forEach(validationError => {
                    errors.push(validationError.param);
                });
                dispatch(ACTION_AUTH_401_ERROR(errors));
            } 
            else {
                dispatch(ACTION_AUTH_500_ERROR());
            }

            dispatch(ACTION_REGISTRATION_COMPLETED());
        }
    }
}

export const login = (email, username, password) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_LOGIN_IN_PROGRESS());
            const response = await api.login(email, username, password);
            if(response && response.data){
                dispatch(ACTION_SET_USER_INFORMATION(response.data.user, response.data.token, response.data.userId));
                dispatch(ACTION_STORE_REFRESH_TOKEN(response.data.refreshToken));
                dispatch(ACTION_LOGIN_COMPLETED());
            }
        } catch(error) {
            if(error.status === 401){
                dispatch(ACTION_AUTH_401_ERROR());
            } 
            else if(error.status === 404){
                dispatch(ACTION_AUTH_404_ERROR());
            }
            else {
                dispatch(ACTION_AUTH_500_ERROR());
            }
            dispatch(ACTION_LOGIN_COMPLETED());
        }
    }
}

export const sendResetPasswordEmail = (email) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_SEND_RESET_PASSSWORD_IN_PROGRESS());
            const response = await api.sendResetPasswordEmail(email);
            if(response && response.data){
                dispatch(ACTION_RESET_EMAIL_SENT())
                dispatch(ACTION_RESET_PASSWORD_MODAL_OPEN(true));
                dispatch(ACTION_SEND_RESET_PASSSWORD_COMPLETED());
            }
        } catch(error) {
            if(error.status === 404){
                dispatch(ACTION_AUTH_404_ERROR());
            }
            else {
                dispatch(ACTION_AUTH_500_ERROR());
            }
            dispatch(ACTION_SEND_RESET_PASSSWORD_COMPLETED());
        }
    }
}

export const resetPassword = (password, userId, token) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_RESET_PASSWORD_IN_PROGRESS());
            const response = await api.resetPassword(password, userId, token);
            if(response && response.data){
                dispatch(ACTION_SET_USER_INFORMATION(response.data.user, response.data.token, response.data.userId));
                dispatch(ACTION_STORE_REFRESH_TOKEN(response.data.refreshToken));
                dispatch(ACTION_RESET_PASSWORD_COMPLETED());
            }
        } catch(error) {
            if(error.status === 401){
                dispatch(ACTION_AUTH_401_ERROR());
            }
            else {
                dispatch(ACTION_AUTH_500_ERROR());
            }
            dispatch(ACTION_RESET_PASSWORD_COMPLETED());
        }
    }
}

export const logout = () => {
    return (dispatch, getState) => {
        dispatch(ACTION_LOGOUT_USER());
        dispatch(ACTION_CLEAR_DASHBOARD_REDUCER());
    }
}

export const setToken = (token) => {
    return (dispatch, getState) => {
        dispatch(ACTION_STORE_JWT_TOKEN(token));
    }
}
export const setRefreshToken = (refreshToken) => {
    return (dispatch, getState) => {
        dispatch(ACTION_STORE_REFRESH_TOKEN(refreshToken));
    }
}
export const removeAuthErrors = () => {
    return (dispatch, getState) => {
        dispatch(ACTION_REMOVE_AUTH_ERRORS());
    }
}

export const resetPasswordModalOpen = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(ACTION_RESET_PASSWORD_MODAL_OPEN(isOpen));
    }
}

export const registrationModalOpen = (isOpen) => {
    return (dispatch, getState) => {
        dispatch(ACTION_REGISTRATION_MODAL_OPEN(isOpen));
    }
}

export const getUserAchievements = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_FETCHING_USER_INFO_IN_PROGRESS());
            const response = await api.getUserInfo();
            if (response?.data?.user) {
                const unlockedAchievements = response.data.user.achievements.filter(achievement => achievement.unlocked === true);
                const lockedAchievements = response.data.user.achievements.filter(achievement => achievement.unlocked === false);
                dispatch(ACTION_STORE_USER_ACHIEVEMENTS(unlockedAchievements, lockedAchievements))
            }
            dispatch(ACTION_FETCHING_USER_INFO_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_USER_INFO_COMPLETED());
        }
    }
}

export const getAllUsers = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_FETCHING_USERS_IN_PROGRESS());
            const response = await api.getAllUsers();
            if (response?.data?.users) {
                dispatch(ACTION_STORE_FETCHED_USERS(response.data.users))
            }
            dispatch(ACTION_FETCHING_USERS_COMPLETED());
        } catch (error) {
            dispatch(ACTION_FETCHING_USERS_COMPLETED());
        }
    }
}

export const deleteUser = (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_DELETING_USER_IN_PROGRESS());
            await api.deleteUser(id);
            
            dispatch(ACTION_DELETING_USER_COMPLETED());
        } catch (error) {
            dispatch(ACTION_DELETING_USER_COMPLETED());
        }
    }
}

export const editUser = (user, id) => {
    return async (dispatch, getState) => {
        try {
            dispatch(ACTION_EDITING_USER_IN_PROGRESS());
            const response = await api.editUser(user, id);
            if(response.data) {
                history.push('/dashboard/users');
            }
            dispatch(ACTION_EDITING_USER_COMPLETED());
        } catch (error) {
            dispatch(ACTION_EDITING_USER_COMPLETED());
        }
    }
}