export const config = {
    minPrice: 0,
    maxPrice: 10000,
    minAge: 0,
    maxAge: 20,
    dogsPerRequest: 12,
    municipalitiesPerRequest: 9,
    apiUrl: 'https://opcinaba-server-nvuvbzcg4a-ew.a.run.app',
    imageUrl: 'https://storage.googleapis.com/opcinaba-upload'
}

export const municipalityConfig = {
    min_popularity: 0,
    min_health: 0,
    max_popularity: 10,
    max_health: 10,
    min_lifespan: 0,
    max_lifespan: 30,
    min_weight: 0,
    max_weight: 350,
    min_height: 0,
    max_height: 45
}