import {
    FETCHING_MAYORS_IN_PROGRESS,
    FETCHING_MAYORS_COMPLETED,
    STORE_FETCHED_MAYORS,
    FETCHING_MAYOR_IN_PROGRESS,
    FETCHING_MAYOR_COMPLETED,
    STORE_FETCHED_MAYOR,
    REMOVE_MAYOR,
    SET_MAYOR_FILTERS,
    CLEAR_MAYOR_REDUCER,
    SET_MAYOR_PAGE,
    SET_MAYOR_TYPE,
    STORE_ADDITIONAL_MAYORS,
    
    FETCHING_ALL_MAYORS_IN_PROGRESS,
    STORE_FETCHED_ALL_MAYORS,
    FETCHING_ALL_MAYORS_COMPLETED,
    EDITING_MAYOR_IN_PROGRESS,
    EDITING_MAYOR_COMPLETED,
    FETCHING_MAYOR_OPTIONS_IN_PROGRESS,
    STORE_MAYOR_OPTIONS,
    FETCHING_MAYOR_OPTIONS_COMPLETED,
    DELETING_MAYOR_IN_PROGRESS,
    DELETING_MAYOR_COMPLETED,
    CREATING_MAYOR_IN_PROGRESS,
    CREATING_MAYOR_COMPLETED,
    FETCHING_ADDITIONAL_MAYORS_IN_PROGRESS,
    FETCHING_ADDITIONAL_MAYORS_COMPLETED
} from '../constants';

/* import { config } from '../../config'; */

const initState = {
    mayors: [],
    totalMayors: 0,
    mayorsShown: 0,
    fetchingMayors: false,
    fetchingAdditionalMayors: false,
    fetchingMayor: false,
    mayor: {},
    type: '',
    page: 1,
    filters: {
        municipalities: [],
        entity: null
    },
    dashboardMayors: [],
    fetchingAllMayors: false,
    mayorOptions: {
        municipalities: []
    },
    fetchingMayorOptions: false,
    creatingMayor: false,
    deletingMayor: false,
    editingMayor: false,
}

const mayorReducer = (state = initState, action) => {

    switch (action.type) {

        case FETCHING_MAYORS_IN_PROGRESS: {
            return {
                ...state,
                fetchingMayors: true
            }
        }

        case FETCHING_MAYORS_COMPLETED: {
            return {
                ...state,
                fetchingMayors: false
            }
        } 

        case STORE_FETCHED_MAYORS: {
            return {
                ...state,
                mayors: action.payload.mayors,
                totalMayors: action.payload.totalMayors,
                mayorsShown: action.payload.mayors.length
            }
        }

        case STORE_ADDITIONAL_MAYORS: {
            return {
                ...state,
                mayors: [...state.mayors, ...action.payload],
                mayorsShown: state.mayorsShown + action.payload.length
            }
        }

        case FETCHING_MAYOR_IN_PROGRESS: {
            return {
                ...state,
                fetchingMayor: true
            }
        }
        case FETCHING_MAYOR_COMPLETED: {
            return {
                ...state,
                fetchingMayor: false
            }
        }
        case STORE_FETCHED_MAYOR: {
            return {
                ...state,
                mayor: action.payload
            }
        }
        case SET_MAYOR_FILTERS: {
            return {
                ...state,
                filters: action.payload
            }
        }

        case REMOVE_MAYOR: {
            return { 
                ...state,
                mayor: {}
            }
        }

        case CLEAR_MAYOR_REDUCER: {
            return {
                mayors: [],
                totalMayors: 0,
                mayorsShown: 0,
                fetchingMayors: false,
                fetchingMayor: false,
                mayor: {},
                type: '',
                page: 1,
                filters: {
                    municipalities: [],
                    entity: null
                }
            }
        }

        case SET_MAYOR_PAGE: {
            return {
                ...state,
                page: action.payload
            }
        }

        case SET_MAYOR_TYPE: {
            return {
                ...state,
                type: action.payload
            }
        }

        case FETCHING_ALL_MAYORS_IN_PROGRESS: {
            return {
                ...state,
                fetchingAllMayors: true
            }
        }
        case STORE_FETCHED_ALL_MAYORS: {
            return {
                ...state,
                dashboardMayors: action.payload
            }
        }
        case FETCHING_ALL_MAYORS_COMPLETED: {
            return {
                ...state,
                fetchingAllMayors: false
            }
        }
        case EDITING_MAYOR_IN_PROGRESS: {
            return {
                ...state,
                editingMayor: true
            }
        }
        case EDITING_MAYOR_COMPLETED: {
            return {
                ...state,
                editingMayor: false
            }
        }
        case FETCHING_MAYOR_OPTIONS_IN_PROGRESS: {
            return {
                ...state,
                fetchingMayorOptions: true
            }
        }
        case STORE_MAYOR_OPTIONS: {
            return {
                ...state,
                mayorOptions: action.payload
            }
        }
        case FETCHING_MAYOR_OPTIONS_COMPLETED: {
            return {
                ...state,
                fetchingMayorOptions: false
            }
        }
        case CREATING_MAYOR_IN_PROGRESS: {
            return {
                ...state,
                creatingMayor: true
            }
        }
        case CREATING_MAYOR_COMPLETED: {
            return {
                ...state,
                creatingMayor: false
            }
        }
        case DELETING_MAYOR_IN_PROGRESS: {
            return {
                ...state,
                deletingMayor: true
            }
        }
        case DELETING_MAYOR_COMPLETED: {
            return {
                ...state,
                deletingMayor: false
            }
        }
        case FETCHING_ADDITIONAL_MAYORS_IN_PROGRESS: {
            return {
                ...state,
                fetchingAdditionalMayors: true
            }
        }
        case FETCHING_ADDITIONAL_MAYORS_COMPLETED: {
            return {
                ...state,
                fetchingAdditionalMayors: false
            }
        }
        default: {
            return state;
        }
    }
}

export default mayorReducer;