import {
    FETCHING_MUNICIPALITIES_IN_PROGRESS,
    FETCHING_MUNICIPALITIES_COMPLETED,
    STORE_FETCHED_MUNICIPALITIES,
    SET_MUNICIPALITY_PAGE,
    STORE_ADDITIONAL_MUNICIPALITIES,
    FETCHING_MUNICIPALITY_IN_PROGRESS,
    STORE_FETCHED_MUNICIPALITY,
    FETCHING_MUNICIPALITY_COMPLETED,
    REMOVE_MUNICIPALITY,
    SET_MUNICIPALITY_FILTERS,
    CLEAR_MUNICIPALITY_REDUCER,
    FETCHING_ALL_MUNICIPALITIES_IN_PROGRESS,
    STORE_FETCHED_ALL_MUNICIPALITIES,
    FETCHING_ALL_MUNICIPALITIES_COMPLETED,
    EDITING_MUNICIPALITY_IN_PROGRESS,
    EDITING_MUNICIPALITY_COMPLETED,
    FETCHING_MUNICIPALITY_OPTIONS_IN_PROGRESS,
    STORE_MUNICIPALITY_OPTIONS,
    FETCHING_MUNICIPALITY_OPTIONS_COMPLETED,
    CREATING_MUNICIPALITY_IN_PROGRESS,
    CREATING_MUNICIPALITY_COMPLETED,
    DELETING_MUNICIPALITY_IN_PROGRESS,
    DELETING_MUNICIPALITY_COMPLETED,
    FETCHING_ADDITIONAL_MUNICIPALITIES_IN_PROGRESS,
    FETCHING_ADDITIONAL_MUNICIPALITIES_COMPLETED
} from '../constants';

/* import { municipalityConfig } from '../../config'; */

const initState = {
    municipalities: [],
    totalMunicipalities: 0,
    municipalitiesShown: 0,
    fetchingMunicipalities: false,
    fetchingAdditionalMunicipalities: false,
    fetchingMunicipality: false,
    municipality: {},
    page: 1,
    filters: {
        entity: null,
        cantons: []
    },
    dashboardMunicipalities: [],
    fetchingAllMunicipalities: false,
    editingMunicipality: false,
    fetchingMunicipalityOptions: false,
    creatingMunicipality: false,
    municipalityOptions: {
        mayors: []
    },
    deletingMunicipality: false
}

const municipalityReducer = (state = initState, action) => {

    switch (action.type) {

        case FETCHING_MUNICIPALITIES_IN_PROGRESS: {
            return {
                ...state,
                fetchingMunicipalities: true
            }
        }

        case FETCHING_MUNICIPALITIES_COMPLETED: {
            return {
                ...state,
                fetchingMunicipalities: false
            }
        }

        case STORE_FETCHED_MUNICIPALITIES: {
            return {
                ...state,
                municipalities: action.payload.municipalities,
                totalMunicipalities: action.payload.totalMunicipalities,
                municipalitiesShown: action.payload.municipalities.length
            }
        }
      
        case STORE_ADDITIONAL_MUNICIPALITIES: {
            return {
                ...state,
                municipalities: [...state.municipalities, ...action.payload],
                municipalitiesShown: state.municipalitiesShown + action.payload.length
            }
        }

        case SET_MUNICIPALITY_PAGE: {
            return {
                ...state,
                page: action.payload
            }
        }
        
        case FETCHING_MUNICIPALITY_IN_PROGRESS: {
            return {
                ...state,
                fetchingMunicipality: true
            }
        }
        
        case FETCHING_MUNICIPALITY_COMPLETED: {
            return {
                ...state,
                fetchingMunicipality: false
            }
        }

        case STORE_FETCHED_MUNICIPALITY: {
            return {
                ...state,
                municipality: action.payload
            }
        }

        case REMOVE_MUNICIPALITY: {
            return { 
                ...state,
                municipality: {}
            }
        }

        case SET_MUNICIPALITY_FILTERS: {
            return {
                ...state,
                filters: action.payload
            }
        }

        case CLEAR_MUNICIPALITY_REDUCER: {
            return {
                ...initState,
                filters: {
                    entity: null,
                    cantons: []
                }
            }
        }
        case FETCHING_ALL_MUNICIPALITIES_IN_PROGRESS: {
            return {
                ...state,
                fetchingAllMunicipalities: true
            }
        }
        case STORE_FETCHED_ALL_MUNICIPALITIES: {
            return {
                ...state,
                dashboardMunicipalities: action.payload
            }
        }
        case FETCHING_ALL_MUNICIPALITIES_COMPLETED: {
            return {
                ...state,
                fetchingAllMunicipalities: false
            }
        }
        case EDITING_MUNICIPALITY_IN_PROGRESS: {
            return {
                ...state,
                editingMunicipality: true
            }
        }
        case EDITING_MUNICIPALITY_COMPLETED: {
            return {
                ...state,
                editingMunicipality: false
            }
        }
        case FETCHING_MUNICIPALITY_OPTIONS_IN_PROGRESS: {
            return {
                ...state,
                fetchingMunicipalityOptions: true
            }
        }
        case STORE_MUNICIPALITY_OPTIONS: {
            return {
                ...state,
                municipalityOptions: action.payload
            }
        }
        case FETCHING_MUNICIPALITY_OPTIONS_COMPLETED: {
            return {
                ...state,
                fetchingMunicipalityOptions: false
            }
        }
        case CREATING_MUNICIPALITY_IN_PROGRESS: {
            return {
                ...state,
                creatingMunicipality: true
            }
        }
        case CREATING_MUNICIPALITY_COMPLETED: {
            return {
                ...state,
                creatingMunicipality: false
            }
        }
        case DELETING_MUNICIPALITY_IN_PROGRESS: {
            return {
                ...state,
                deletingMunicipality: true
            }
        }
        case DELETING_MUNICIPALITY_COMPLETED: {
            return {
                ...state,
                deletingMunicipality: false
            }
        }
        case FETCHING_ADDITIONAL_MUNICIPALITIES_IN_PROGRESS: {
            return {
                ...state,
                fetchingAdditionalMunicipalities: true
            }
        }
        case FETCHING_ADDITIONAL_MUNICIPALITIES_COMPLETED: {
            return {
                ...state,
                fetchingAdditionalMunicipalities: false
            }
        }
        default: {
            return state;
        }
    }
}

export default municipalityReducer;