import { 
    STORE_SESSION_ID,
    SENDING_CONTACT_IN_PROGRESS,
    SENDING_CONTACT_COMPLETED,
    CONTACT_MODAL_OPEN
} from '../constants';

const initState = {
    sessionId: null,
    region: 'FB',
    sendingContactRequest: false,
    contactModalOpen: false
}

const commonReducer = (state = initState, action) => {
    switch (action.type) {  
        case STORE_SESSION_ID: {
            return {
                ...state,
                sessionId: action.payload.sessionId,
                region: action.payload.region
            }
        }  
        case SENDING_CONTACT_IN_PROGRESS: {
            return {
                ...state,
                sendingContactRequest: true
            }
        }
        case SENDING_CONTACT_COMPLETED: {
            return {
                ...state,
                sendingContactRequest: false
            }
        }
        case CONTACT_MODAL_OPEN: {
            return {
                ...state,
                contactModalOpen: action.payload
            }
        }
        default: {
            return state;
        }
    }
}

export default commonReducer;