import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../store/actions/authActions';

import logo from '../../assets/images/opcinaLogo.svg';
import opstinaLogo from '../../assets/images/opstinaLogo.svg';

/* import arrow from '../../assets/icons/arrowdown.svg';
import arrowYellow from '../../assets/icons/arrowdown-yellow.svg'; */

class Header extends Component {
    
    render() {
        const activePath = this.props.location.pathname;
        const { region } = this.props;
        return (
            <div id="header" className="header">
                <div className="header__logo_container">
                    <Link to="/">
                        {region ? <img src={region === 'FB' ? logo : opstinaLogo} alt="logo" className="header__logo" /> : null}
                    </Link>
                </div>
                
                <div className="header__links">
                    <Link to="/">
                        <div className={`header__link ${activePath === '/' ? 'header__link--active' : ''}`}>
                            Početna
                        </div>
                    </Link>
                    {region ?
                        <Link to="/municipalities">
                            <div className={`header__link ${activePath.startsWith('/municipalities') ? 'header__link--active' : ''}`}>
                                {region === 'FB' ? 'Općine' : 'Opštine'}
                            </div>
                        </Link>
                    :  null}
                    <Link to="/mayors">
                        <div className={`header__link ${activePath.startsWith('/mayors') ? 'header__link--active' : ''}`}>
                            Načelnici
                        </div>
                    </Link>
                    {/* <Link to="/about">
                        <div className={`header__link ${activePath.startsWith('/about') ? 'header__link--active' : ''}`}>
                            O nama
                        </div>
                    </Link> */}
                    <Link to="/contact">
                        <div className={`header__link ${activePath.startsWith('/contact') ? 'header__link--active' : ''}`}>
                            Kontakt
                        </div>
                    </Link>
                    {/*  <div className={`header__link header__list ${this.isResourcePathActive() ? 'header__link--active' : ''}`}>
                        <Link to="/resources">
                            Resources <img src={this.isResourcePathActive() ? arrowYellow : arrow} alt="arrow" className="header__list__arrow" /> 
                        </Link>
                        <div className="header__list__submenu">
                            <Link to="/breeds">
                                <div className={`header__list__submenu__item ${activePath === '/breeds' || activePath.startsWith('/breed/') ? 'header__list__submenu__item--active' : ''}`}>
                                    Breeds <span>&rarr; </span>
                                </div>
                            </Link>
                            <hr className="header__list__submenu__hr" />
                            <Link to="/blog">
                                <div className={`header__list__submenu__item ${activePath === '/blog' ? 'header__list__submenu__item--active' : ''}`}>
                                    Blog <span>&rarr; </span>
                                </div>
                            </Link>
                        </div>
                    </div> */}
                   
                </div>
            </div>
        );
    }
    isResourcePathActive = () => {
        const activePath = this.props.location.pathname;
        return activePath === '/resources' || activePath === '/breeds' || activePath === '/blog' || activePath.startsWith('/breed/');
    }
}
const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        region: state.common.region
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => { dispatch(logout()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));