//* -------------------------------------------------------------- //
//*                    COMMON ENDPOINT CONSTANTS                   //
//* -------------------------------------------------------------- //
export const STORE_SESSION_ID = 'STORE_SESSION_ID';

export const SENDING_CONTACT_IN_PROGRESS = 'SENDING_CONTACT_IN_PROGRESS';
export const SENDING_CONTACT_COMPLETED = 'SENDING_CONTACT_COMPLETED';
export const CONTACT_MODAL_OPEN = 'CONTACT_MODAL_OPEN';
//* -------------------------------------------------------------- //
//*                   MAYOR ENDPOINT CONSTANTS                     //
//* -------------------------------------------------------------- //
//? Constants for fetching multiple mayors
export const FETCHING_MAYORS_IN_PROGRESS = 'FETCHING_MAYORS_IN_PROGRESS';
export const FETCHING_MAYORS_COMPLETED = 'FETCHING_MAYORS_COMPLETED';
export const STORE_FETCHED_MAYORS = 'STORE_FETCHED_MAYORS';
export const STORE_ADDITIONAL_MAYORS = 'STORE_ADDITIONAL_MAYORS';
export const FETCHING_ADDITIONAL_MAYORS_IN_PROGRESS = 'FETCHING_ADDITIONAL_MAYORS_IN_PROGRESS';
export const FETCHING_ADDITIONAL_MAYORS_COMPLETED = 'FETCHING_ADDITIONAL_MAYORS_COMPLETED';
//? Constants for handling a single mayor
export const FETCHING_MAYOR_IN_PROGRESS = 'FETCHING_MAYOR_IN_PROGRESS'; 
export const FETCHING_MAYOR_COMPLETED = 'FETCHING_MAYOR_COMPLETED';
export const STORE_FETCHED_MAYOR = 'STORE_FETCHED_MAYOR';
export const REMOVE_MAYOR = 'REMOVE_MAYOR';
//? General mayor reducer constants
export const CLEAR_MAYOR_REDUCER = 'CLEAR_MAYOR_REDUCER';
export const SET_MAYOR_FILTERS = 'SET_MAYOR_FILTERS';
export const SET_MAYOR_PAGE = 'SET_MAYOR_PAGE';
export const SET_MAYOR_TYPE = 'SET_MAYOR_TYPE';

export const FETCHING_ALL_MAYORS_IN_PROGRESS = 'FETCHING_ALL_MAYORS_IN_PROGRESS';
export const STORE_FETCHED_ALL_MAYORS = 'STORE_FETCHED_ALL_MAYORS';
export const FETCHING_ALL_MAYORS_COMPLETED = 'FETCHING_ALL_MAYORS_COMPLETED';

export const EDITING_MAYOR_IN_PROGRESS = 'EDITING_MAYOR_IN_PROGRESS';
export const EDITING_MAYOR_COMPLETED = 'EDITING_MAYOR_COMPLETED';

export const FETCHING_MAYOR_OPTIONS_IN_PROGRESS = 'FETCHING_MAYOR_OPTIONS_IN_PROGRESS';
export const STORE_MAYOR_OPTIONS = 'STORE_MAYOR_OPTIONS';
export const FETCHING_MAYOR_OPTIONS_COMPLETED = 'FETCHING_MAYOR_OPTIONS_COMPLETED';

export const CREATING_MAYOR_IN_PROGRESS = 'CREATING_MAYOR_IN_PROGRESS';
export const CREATING_MAYOR_COMPLETED = 'CREATING_MAYOR_COMPLETED';

export const DELETING_MAYOR_IN_PROGRESS = 'DELETING_MAYOR_IN_PROGRESS';
export const DELETING_MAYOR_COMPLETED = 'DELETING_MAYOR_COMPLETED';
//* -------------------------------------------------------------- //
//*              MUNICIPALITY ENDPOINT CONSTANTS                   //
//* -------------------------------------------------------------- //
//? Constants for fetching all municipalities
export const FETCHING_MUNICIPALITIES_IN_PROGRESS = 'FETCHING_MUNICIPALITIES_IN_PROGRESS';
export const FETCHING_MUNICIPALITIES_COMPLETED = 'FETCHING_MUNICIPALITIES_COMPLETED';
export const STORE_FETCHED_MUNICIPALITIES = 'STORE_FETCHED_MUNICIPALITIES';
export const STORE_ADDITIONAL_MUNICIPALITIES = 'STORE_ADDITIONAL_MUNICIPALITIES';
export const FETCHING_ADDITIONAL_MUNICIPALITIES_IN_PROGRESS = 'FETCHING_ADDITIONAL_MUNICIPALITIES_IN_PROGRESS';
export const FETCHING_ADDITIONAL_MUNICIPALITIES_COMPLETED = 'FETCHING_ADDITIONAL_MUNICIPALITIES_COMPLETED';
//? Constants for handling a single municipality
export const FETCHING_MUNICIPALITY_IN_PROGRESS = 'FETCHING_MUNICIPALITY_IN_PROGRESS';
export const STORE_FETCHED_MUNICIPALITY = 'STORE_FETCHED_MUNICIPALITY';
export const FETCHING_MUNICIPALITY_COMPLETED = 'FETCHING_MUNICIPALITY_COMPLETED';
//? Constants for handling general municipalities state
export const SET_MUNICIPALITY_PAGE = 'SET_MUNICIPALITY_PAGE';
export const REMOVE_MUNICIPALITY = 'REMOVE_MUNICIPALITY';
export const SET_MUNICIPALITY_FILTERS = 'SET_MUNICIPALITY_FILTERS';
export const CLEAR_MUNICIPALITY_REDUCER = 'CLEAR_MUNICIPALITY_REDUCER';

export const FETCHING_ALL_MUNICIPALITIES_IN_PROGRESS = 'FETCHING_ALL_MUNICIPALITIES_IN_PROGRESS';
export const STORE_FETCHED_ALL_MUNICIPALITIES = 'STORE_FETCHED_ALL_MUNICIPALITIES';
export const FETCHING_ALL_MUNICIPALITIES_COMPLETED = 'FETCHING_ALL_MUNICIPALITIES_COMPLETED';

export const EDITING_MUNICIPALITY_IN_PROGRESS = 'EDITING_MUNICIPALITY_IN_PROGRESS';
export const EDITING_MUNICIPALITY_COMPLETED = 'EDITING_MUNICIPALITY_COMPLETED';

export const FETCHING_MUNICIPALITY_OPTIONS_IN_PROGRESS = 'FETCHING_MUNICIPALITY_OPTIONS_IN_PROGRESS';
export const STORE_MUNICIPALITY_OPTIONS = 'STORE_MUNICIPALITY_OPTIONS';
export const FETCHING_MUNICIPALITY_OPTIONS_COMPLETED = 'FETCHING_MUNICIPALITY_OPTIONS_COMPLETED';

export const CREATING_MUNICIPALITY_IN_PROGRESS = 'CREATING_MUNICIPALITY_IN_PROGRESS';
export const CREATING_MUNICIPALITY_COMPLETED = 'CREATING_MUNICIPALITY_COMPLETED';

export const DELETING_MUNICIPALITY_IN_PROGRESS = 'DELETING_MUNICIPALITY_IN_PROGRESS';
export const DELETING_MUNICIPALITY_COMPLETED = 'DELETING_MUNICIPALITY_COMPLETED';
//* -------------------------------------------------------------- //
//*                    AUTH ENDPOINT CONSTANTS                     //
//* -------------------------------------------------------------- //
//? Constants for handling registration endpoint
export const REGISTRATION_IN_PROGRESS = 'REGISTRATION_IN_PROGRESS';
export const REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED';
export const REGISTRATION_MODAL_OPEN = 'REGISTRATION_MODAL_OPEN';
//? Constants for handling login endpoint
export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';
//? Constants for handling sending reset password endpoint
export const SEND_RESET_PASSSWORD_IN_PROGRESS = 'SEND_RESET_PASSSWORD_IN_PROGRESS';
export const SEND_RESET_PASSSWORD_COMPLETED = 'SEND_RESET_PASSSWORD_COMPLETED';
export const RESET_EMAIL_SENT = 'RESET_EMAIL_SENT';
export const RESET_PASSWORD_MODAL_OPEN = 'RESET_PASSWORD_MODAL_OPEN';
//? Constants for handling reseting password endpoint
export const RESET_PASSWORD_IN_PROGRESS = 'RESET_PASSWORD_IN_PROGRESS';
export const RESET_PASSWORD_COMPLETED = 'RESET_PASSWORD_COMPLETED';
//? Constants for handling general authentication state
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const STORE_JWT_TOKEN = 'STORE_JWT_TOKEN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const STORE_REFRESH_TOKEN = 'STORE_REFRESH_TOKEN';
export const STORE_USER_NOTIFICATION = 'STORE_USER_NOTIFICATION';
//?  Constants for error handling
export const AUTH_401_ERROR = 'AUTH_401_ERROR';
export const AUTH_404_ERROR = 'AUTH_404_ERROR';
export const AUTH_500_ERROR = 'AUTH_500_ERROR';
export const REMOVE_AUTH_ERRORS = 'REMOVE_AUTH_ERRORS';
//? Get user info constants
export const FETCHING_USER_INFO_IN_PROGRESS = 'FETCHING_USER_INFO_IN_PROGRESS';
export const FETCHING_USER_INFO_COMPLETED = 'FETCHING_USER_INFO_COMPLETED';
export const STORE_USER_ACHIEVEMENTS = 'STORE_USER_ACHIEVEMENTS';

export const FETCHING_USERS_IN_PROGRESS = 'FETCHING_USERS_IN_PROGRESS';
export const FETCHING_USERS_COMPLETED = 'FETCHING_USERS_COMPLETED';
export const STORE_FETCHED_USERS = 'STORE_FETCHED_USERS';

export const DELETING_USER_IN_PROGRESS = 'DELETING_USER_IN_PROGRESS';
export const DELETING_USER_COMPLETED = 'DELETING_USER_COMPLETED';

export const EDITING_USER_IN_PROGRESS = 'EDITING_USER_IN_PROGRESS';
export const EDITING_USER_COMPLETED = 'EDITING_USER_COMPLETED';
//* -------------------------------------------------------------- //
//*                 STATISTICS ENDPOINT CONSTANTS                  //
//* -------------------------------------------------------------- //
export const SET_DASHBOARD_STATISTICS = 'SET_DASHBOARD_STATISTICS';
export const FETCHING_DASHBOARD_STATISTICS_IN_PROGRESS = 'FETCHING_DASHBOARD_STATISTICS_IN_PROGRESS';
export const FETCHING_DASHBOARD_STATISTICS_COMPLETED = 'FETCHING_DASHBOARD_STATISTICS_COMPLETED';

//* -------------------------------------------------------------- //
//*               DASHBOARD ENDPOINT CONSTANTS                     //
//* -------------------------------------------------------------- //
//? Constants for handling general dashboard state
export const STORE_USER_MAYORS = 'STORE_USER_MAYORS';
export const SET_SETTINGS_PAGE = 'SET_SETTINGS_PAGE';
export const SET_DASHBOARD_SETTINGS = 'SET_DASHBOARD_SETTINGS';
export const CLEAR_DASHBOARD_REDUCER = 'CLEAR_DASHBOARD_REDUCER';

//* -------------------------------------------------------------- //
//*                    BLOG ENDPOINT CONSTANTS                     //
//* -------------------------------------------------------------- //
export const FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS = 'FETCHING_MAIN_BLOG_POSTS_IN_PROGRESS';
export const FETCHING_MAIN_BLOG_POSTS_COMPLETED = 'FETCHING_MAIN_BLOG_POSTS_COMPLETED';
export const STORE_MAIN_BLOG_POSTS = 'STORE_MAIN_BLOG_POSTS';
export const STORE_BLOG_CATEGORIES = 'STORE_BLOG_CATEGORIES';

export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const FETCHING_CATEGORY_POSTS_IN_PROGRESS = 'FETCHING_CATEGORY_POSTS_IN_PROGRESS';
export const FETCHING_CATEGORY_POSTS_COMPLETED = 'FETCHING_CATEGORY_POSTS_COMPLETED';
export const STORE_FETCHED_POSTS = 'STORE_FETCHED_POSTS';
export const SET_BLOG_PAGE = 'SET_BLOG_PAGE';
export const STORE_ADDITIONAL_POSTS = 'STORE_ADDITIONAL_POSTS';
export const CLEAR_BLOG_STATE = 'CLEAR_BLOG_STATE';


export const FETCHING_ALL_POSTS_IN_PROGRESS = 'FETCHING_ALL_POSTS_IN_PROGRESS';
export const STORE_FETCHED_ALL_POSTS = 'STORE_FETCHED_ALL_POSTS';
export const FETCHING_ALL_POSTS_COMPLETED = 'FETCHING_ALL_POSTS_COMPLETED';

export const FETCHING_ALL_CATEGORIES_IN_PROGRESS = 'FETCHING_ALL_CATEGORIES_IN_PROGRESS';
export const STORE_FETCHED_ALL_CATEGORIES = 'STORE_FETCHED_ALL_CATEGORIES';
export const FETCHING_ALL_CATEGORIES_COMPLETED = 'FETCHING_ALL_CATEGORIES_COMPLETED';

export const CREATING_POST_IN_PROGRESS = 'CREATING_POST_IN_PROGRESS';
export const STORE_CREATED_POST = 'STORE_CREATED_POST';
export const CREATING_POST_COMPLETED = 'CREATING_POST_COMPLETED';

export const FETCHING_POST_OPTIONS_IN_PROGRESS = 'FETCHING_POST_OPTIONS_IN_PROGRESS';
export const FETCHING_POST_OPTIONS_COMPLETED = 'FETCHING_POST_OPTIONS_COMPLETED';
export const STORE_POST_OPTIONS = 'STORE_POST_OPTIONS';

export const EDITING_POST_IN_PROGRESS = 'EDITING_POST_IN_PROGRESS';
export const EDITING_POST_COMPLETED = 'EDITING_POST_COMPLETED';

export const DELETING_POST_IN_PROGRESS = 'DELETING_POST_IN_PROGRESS';
export const DELETING_POST_COMPLETED = 'DELETING_POST_COMPLETED';

export const DELETING_CATEGORY_IN_PROGRESS = 'DELETING_CATEGORY_IN_PROGRESS';
export const DELETING_CATEGORY_COMPLETED = 'DELETING_CATEGORY_COMPLETED';

export const CREATING_CATEGORY_IN_PROGRESS = 'CREATING_CATEGORY_IN_PROGRESS';
export const CREATING_CATEGORY_COMPLETED = 'CREATING_CATEGORY_COMPLETED';

export const EDITING_CATEGORY_IN_PROGRESS = 'EDITING_CATEGORY_IN_PROGRESS';
export const EDITING_CATEGORY_COMPLETED = 'EDITING_CATEGORY_COMPLETED';

export const FETCHING_SINGLE_POST_IN_PROGRESS = 'FETCHING_SINGLE_POST_IN_PROGRESS';
export const FETCHING_SINGLE_POST_COMPLETED = 'FETCHING_SINGLE_POST_COMPLETED';
export const STORE_FETCHED_POST = 'STORE_FETCHED_POST';

export const FETCHING_SEARCH_POSTS_IN_PROGRESS = 'FETCHING_SEARCH_POSTS_IN_PROGRESS';
export const FETCHING_SEARCH_POSTS_COMPLETED = 'FETCHING_SEARCH_POSTS_COMPLETED';
export const STORE_SEARCH_POSTS = 'STORE_SEARCH_POSTS';